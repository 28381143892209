<template>
  <div class="pa-4 mt-4">
    <div class="subheading font-weight-bold">
      Data Management
    </div>
    <div class="px-4 mt-2 mb-5">
      <div class="subheading my-3">
        Sometimes it is useful to start with a fresh lab database. We recommend creating a new lab instead as a safer option.
      </div>
      <v-alert
        :value="!isLabManager()"
        class="font-weight-bold my-3"
        type="info"
        outline
      >
        Only lab managers can manage lab data.
      </v-alert>
      <v-alert
        :value="isLabManager() && !showClearDataWarning && !showDeleteLabWarning"
        class="font-weight-bold my-3"
        type="error"
        outline
      >
        These options are <span class="font-weight-bold">destructive</span>. You will be prompted to confirm before any data is deleted.
      </v-alert>
      <v-btn
        @click="clearData"
        color="red"
        class="text-capitalize white--text ml-0 mr-2"
        :disabled="!isLabManager()"
        v-if="!showClearDataWarning && !showDeleteLabWarning"
      >
        Clear Lab Data
      </v-btn>
      <v-btn
        @click="deleteLab"
        color="red"
        class="text-capitalize white--text mx-2"
        :disabled="!isLabManager()"
        v-if="!showClearDataWarning && !showDeleteLabWarning"
      >
        Delete Lab
      </v-btn>
      <div v-if="showClearDataWarning">
        <div class="subheading red--text">
          Proceeding will delete data that is incorporated across analyses (e.g. cluster data). No existing analysis results will be impacted. Existing data will be erased and will not be incorporated into future analyses.
        </div>
        <div class="subheading red--text my-3">
          Type the name of the lab ({{ lab.name }}) and click confirm to proceed.
        </div>
        <v-text-field
          label="Lab Name"
          name="confirm"
          v-model="clearDataText"
        ></v-text-field>
        <v-btn
          @click="clearDataConfirmed"
          color="red"
          class="text-capitalize white--text mx-0"
          :disabled="clearDataText !== lab.name || dataDeleted"
          v-if="showClearDataWarning"
        >
          Confirm
        </v-btn>
        <div v-if="dataDeleted" class="mt-2">Success</div>
      </div>
      <div v-if="showDeleteLabWarning">
        <div class="subheading red--text">
          Proceeding will delete the entire lab. No user accounts will be deleted. Prior analyses will not be deleted, but members may lose access if their access is granted through the lab.
        </div>
        <div class="subheading red--text my-3">
          Type the name of the lab ({{ lab.name }}) and click confirm to proceed.
        </div>
        <v-text-field
          label="Lab Name"
          name="confirm"
          v-model="deleteLabText"
        ></v-text-field>
        <v-btn
          @click="deleteLabConfirmed"
          color="red"
          class="text-capitalize white--text mx-0"
          :disabled="deleteLabText !== lab.name"
          v-if="showDeleteLabWarning"
        >
          Confirm
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import {
  dispatchGetLabMembership,
  dispatchClearLabData,
  dispatchDeleteLab,
} from "@bugseq-site/app/src/store/modules/api/actions";
import { readUserProfile } from "@bugseq-site/app/src/store/modules/api/getters";

const ComponentProps = Vue.extend({
  props: {
    lab: Object,
    disabled: Boolean,
  },
});

@Component
export default class LabTabDataManagement extends ComponentProps {
  private showClearDataWarning: boolean = false
  private clearDataText: string = ""
  private dataDeleted: boolean = false

  private showDeleteLabWarning: boolean = false
  private deleteLabText: string = ""

  private isLabManager() {
    const user = readUserProfile(this.$store)
    if (!user || !this.lab) {
      return false
    }

    return this.lab.manager_id === user.id
  }

  private clearData() {
    this.showClearDataWarning = true
  }

  private async clearDataConfirmed() {
    await dispatchClearLabData(this.$store, { labId: this.lab.id })
    this.dataDeleted = true
  }

  private deleteLab() {
    this.showDeleteLabWarning = true
  }

  private async deleteLabConfirmed() {
    await dispatchDeleteLab(this.$store, { labId: this.lab.id })
    await dispatchGetLabMembership(this.$store); // refresh
    await this.$router.push({ path: "/app/main/labs" })
  }
}
</script>
