import { getStoreAccessors } from 'typesafe-vuex'
import { DrawerState } from './state'
import { RootState } from '../../state'

export const getters = {
  dashboardShowDrawer: (state: DrawerState) => state.dashboardShowDrawer,
  dashboardMiniDrawer: (state: DrawerState) => state.dashboardMiniDrawer
}

const { read } = getStoreAccessors<DrawerState, RootState>('drawer')

export const readDashboardMiniDrawer = read(getters.dashboardMiniDrawer)
export const readDashboardShowDrawer = read(getters.dashboardShowDrawer)
