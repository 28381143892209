import Vuex, { Store } from 'vuex'
import Router from 'vue-router'
import { RootState } from './state'
import { Client } from '@bugseq-site/shared/src/lib/api/client'
import { paths } from '@bugseq-site/app/src/lib/api/api'
import { createApiModule } from './modules/api'
import { notifications } from './modules/notifications'
import { drawer } from './modules/drawer'

export const createStore = (client: Client<paths>, router: Router): Store<RootState> => new Vuex.Store<RootState>({
  modules: {
    api: createApiModule(client, router),
    notifications,
    drawer
  }
})
