<template>
  <v-app>
    <v-content class="pb-5">
      <SubmissionForm archived_publication_analysis />
    </v-content>
  </v-app>
</template>

<script>
import SubmissionForm from "@bugseq-site/app/src/components/app/SubmissionForm";

export default {
  metaInfo: {
    title: "Academic",
  },
  components: {
    SubmissionForm,
  },
};
</script>
