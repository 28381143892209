<template>
  <v-card class="elevation-12 mt-4">
    <v-toolbar dark color="primary">
      <v-toolbar-title>Register</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-card-text>
      <div class="my-3">An email was sent to {{ email }} with a confirmation code. Please enter it:</div>
      <v-form ref="form" lazy-validation>
        <v-text-field
          label="Confirmation Code"
          name="Confirmation Code"
          v-model="confirmationCode"
          :error-messages="getError(fields, errors, 'Confirmation Code')"
        ></v-text-field>
        <div v-if="errorMessage" class="red--text">{{ errorMessage }}</div>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn
        @click="resend"
        class="text-capitalize"
        :disabled="inProgress || codeSent"
      >
        <span v-if="!codeSent">Resend Code</span>
        <span v-else>Sent</span>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        @click="submit"
        type="submit"
        class="text-capitalize"
        color="primary"
        :disabled="errors.items.length > 0 || inProgress"
      >
        Next
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import { PropType } from "vue";
import { Component, Vue } from "vue-property-decorator";
import { confirmSignUp, resendSignUpCode, autoSignIn } from 'aws-amplify/auth';

const ComponentProps = Vue.extend({
  props: {
    next: Function as PropType<() => void>,
  },
});

@Component({
  methods: {
    getError: (fields, errors, field) => {
      if (!(field in fields)) {
        return "";
      }
      if (!fields[field].dirty) {
        return "";
      }
      return errors.collect(field);
    },
  },
})
export default class RegisterEmail extends ComponentProps {
  public email: string = "";
  public confirmationCode: string = "";
  public errorMessage: string = "";
  public codeSent: boolean = false;

  public inProgress = false;

  public async mounted() {
    this.email = (this.$route.query.email || "<email not found>") as string
  }

  public async resend() {
    this.inProgress = true;
    try {
      await resendSignUpCode({
        username: this.email,
      })
      this.codeSent = true;
    } catch (error) {
      if (error instanceof Error) {
        this.errorMessage = `Error resending code: ${error.message}`
      } else {
        console.error("could not parse error", error)
        this.errorMessage = "Unknown error"
      }
    } finally {
      this.inProgress = false;
    }
  }

  public async nextStep() {
    this.$router.push({ query: { ...this.$route.query, step: "mfa", email: null } })
    this.next()
  }

  public async submit(event) {
    event.preventDefault()

    this.inProgress = true;

    try {
      const { isSignUpComplete, nextStep } = await confirmSignUp({
        username: this.email,
        confirmationCode: this.confirmationCode
      });

      this.errorMessage = "";
      if (!isSignUpComplete) {
        throw new Error("Expected signup to be complete")
      }

      const autoSignInResp = await autoSignIn()
      if (autoSignInResp.nextStep.signInStep !== "DONE") {
        throw new Error("Expected signin to be done")
      }

      this.nextStep()
      return
    } catch (error) {
      if (error instanceof Error) {
        if (error.message.includes("Current status is CONFIRMED")) {
          // user is already confirmed
          // move to next step
          this.nextStep()
          return
        }
        this.errorMessage = error.message
      } else {
        console.error("could not parse error", error)
        this.errorMessage = "Unknown error"
      }
    } finally {
      this.inProgress = false;
    }
  }
}
</script>

<style scoped>
.required >>> label::after {
  content: " *";
  color: red;
}
</style>
