<template>
  <div>
    <div class="mt-5 mb-3">
      Share Current Analysis
    </div>
    <div class="mb-2">
      You can share these results with other members of your lab. They will
      receive an email with a link to access the results. Visit the
      <a href="/app/main/labs">labs</a> page to create a lab or add members.
    </div>
    <div
      class="mb-4 red--text"
      v-if="user && !free && results.owner_id !== user.id"
    >
      Only your own analysis results can be shared, not results that have been
      shared with you.
    </div>
    <div class="mb-4" v-else-if="labs.length === 0">
      It looks like you are not a member of any labs. Visit the
      <a href="/app/main/labs">labs</a> page to get started.
    </div>
    <div class="mb-4" v-else>
      <div v-for="lab in labs" :key="lab.id">
        <div class="font-weight-bold pt-2">{{ lab.name }}</div>
        <v-list two-line subheader class="mb-4">
          <v-list-tile>
            <v-list-tile-content>
              <v-list-tile-title
                >Entire Lab (see <a :href="`/app/main/labs/${lab.id}`" target="_blank">members</a>)</v-list-tile-title
              >
              <v-list-tile-sub-title
                >Analysis will be visible to all members in the lab</v-list-tile-sub-title
              >
            </v-list-tile-content>
            <v-list-tile-action>
              <v-btn
                color="primary"
                class="text-capitalize"
                v-on:click="shareWithLab(lab.id, lab.id)"
                >Share</v-btn
              >
            </v-list-tile-action>
          </v-list-tile>
          <v-list-tile
            v-for="member in lab.members.filter(
              (m) => user && m.id !== user.id,
            )"
            :key="member.id"
          >
            <v-list-tile-content>
              <v-list-tile-title
                >{{ member.first_name }}
                {{ member.last_name }}</v-list-tile-title
              >
              <v-list-tile-sub-title
                ><a :href="`mailto:${member.email}`">{{
                  member.email
                }}</a></v-list-tile-sub-title
              >
            </v-list-tile-content>
            <v-list-tile-action>
              <v-btn
                color="primary"
                class="text-capitalize"
                v-on:click="shareWithUser(lab.id, member.id)"
                >Share</v-btn
              >
            </v-list-tile-action>
          </v-list-tile>
        </v-list>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import {
  dispatchShareJob,
} from "@bugseq-site/app/src/store/modules/api/actions";
import { readUserProfile, readLabs } from "@bugseq-site/app/src/store/modules/api/getters";

const ComponentProps = Vue.extend({
  props: {
    free: Boolean,
    results: Object,
  },
});

@Component
export default class JobResultsShare extends ComponentProps {
  get user() {
    return readUserProfile(this.$store);
  }

  get labs() {
    return readLabs(this.$store);
  }

  public async shareWithLab(sharedViaLabId, sharedWithLabId) {
    await dispatchShareJob(this.$store, {
      jobId: this.results.id,
      sharedViaLabId,
      sharedWithLabId,
    });
  }

  public async shareWithUser(sharedViaLabId, sharedWithUserId) {
    await dispatchShareJob(this.$store, {
      jobId: this.results.id,
      sharedViaLabId,
      sharedWithUserId,
    });
  }
}
</script>
