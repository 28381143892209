<template>
  <v-navigation-drawer
    persistent
    :mini-variant="miniDrawer"
    v-model="showDrawer"
    fixed
    app
  >
    <v-layout column fill-height>
      <v-list>
        <v-subheader>Main menu</v-subheader>
        <v-list-tile to="/app/main/dashboard">
          <v-list-tile-action>
            <v-icon>addchart</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>New Analysis</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile to="/app/main/history">
          <v-list-tile-action>
            <v-icon>history</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Previous Analyses</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile to="/app/main/explore">
          <v-list-tile-action>
            <v-icon>query_stats</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Insights</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile to="/app/main/labs">
          <v-list-tile-action>
            <v-icon>groups</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Labs</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile href="https://docs.bugseq.com" target="_blank">
          <v-list-tile-action>
            <v-icon>menu_book</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Documentation</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile to="/app/main/card" style="visibility: hidden;">
          <v-list-tile-action>
            <v-icon>payment</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Payment</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
      <v-divider></v-divider>
      <v-spacer></v-spacer>
      <v-list>
        <v-list-tile @click="logout">
          <v-list-tile-action>
            <v-icon>close</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Logout</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-divider></v-divider>
        <v-list-tile @click="switchMiniDrawer">
          <v-list-tile-action>
            <v-icon
              v-html="miniDrawer ? 'chevron_right' : 'chevron_left'"
            ></v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Collapse</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-layout>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";

import {
  readDashboardMiniDrawer,
  readDashboardShowDrawer,
} from "@bugseq-site/app/src/store/modules/drawer/getters";
import {
  dispatchSetDashboardShowDrawer,
  dispatchSetDashboardMiniDrawer,
} from "@bugseq-site/app/src/store/modules/drawer/actions";
import { dispatchUserLogOut } from "@bugseq-site/app/src/store/modules/api/actions";

@Component
export default class AppNav extends Vue {
  get miniDrawer() {
    return readDashboardMiniDrawer(this.$store);
  }

  get showDrawer() {
    return readDashboardShowDrawer(this.$store);
  }

  set showDrawer(value) {
    dispatchSetDashboardShowDrawer(this.$store, value);
  }

  public switchMiniDrawer() {
    dispatchSetDashboardMiniDrawer(
      this.$store,
      !readDashboardMiniDrawer(this.$store),
    );
  }

  public async logout() {
    await dispatchUserLogOut(this.$store);
  }
}
</script>
