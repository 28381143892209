import { getCurrentAppUrl, getEnv, BugSeqEnv, mustGetEnv } from '@bugseq-site/shared/src/env'

export function getCognitoURL (): string {
  if (getEnv() === BugSeqEnv.Prod) {
    return 'auth.bugseq.com'
  }

  return 'devauth.bugseq.com'
}

interface CognitoPoolConfig {
  clientId: string
  userPoolId: string
}

export function getCognitoPoolData (): CognitoPoolConfig {
  return {
    clientId: mustGetEnv('VUE_APP_COGNITO_CLIENT_ID'),
    userPoolId: mustGetEnv('VUE_APP_COGNITO_USER_POOL_ID')
  }
}

export function getCognitoRedirectURL (): string {
  return `${getCurrentAppUrl()}/app/cognito`
}

export function getCognitoRedirectSignOutURL (): string {
  return getCurrentAppUrl()
}
