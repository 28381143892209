<template>
  <div>
    <div class="pa-4" v-if="lab">
      <div class="pb-2">
        <v-btn
          class="text-capitalize mx-0 pl-0 pr-2"
          flat
          to="/app/main/labs"
          active-class=""
        ><v-icon>keyboard_double_arrow_left</v-icon>Back to Labs</v-btn>
      </div>
      <v-card class="pa-4">
        <div class="mx-4">
          <div class="pb-2 headline">
            Lab {{ lab.name }}
          </div>
          <div class="pb-3">
            ID: {{ lab.id }}
          </div>
        </div>
        <v-tabs fixed-tabs class="mt-3">
          <v-tab
            v-for="tab in tabs"
            :key="tab.name"
          >
            {{ tab.name }}
          </v-tab>
          <v-tab-item
            key="Members"
          >
            <LabTabMembers :lab="lab" />
          </v-tab-item>
          <v-tab-item
            key="Data Access & Notifications"
          >
            <LabTabDataAccessAndNotifications :lab="lab" />
          </v-tab-item>
          <v-tab-item
            key="Data Management"
          >
            <LabTabDataManagement :lab="lab" />
          </v-tab-item>
        </v-tabs>
      </v-card>
    </div>
    <div v-else>
      <v-layout justify-center>
        <v-progress-circular
          indeterminate
          color="black"
        ></v-progress-circular>
      </v-layout>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import { readLabs } from "@bugseq-site/app/src/store/modules/api/getters";
import LabTabMembers from "@bugseq-site/app/src/components/app/LabTabMembers.vue";
import LabTabDataAccessAndNotifications from "@bugseq-site/app/src/components/app/LabTabDataAccessAndNotifications.vue";
import LabTabDataManagement from "@bugseq-site/app/src/components/app/LabTabDataManagement.vue";

@Component({
  components: {
    LabTabMembers,
    LabTabDataAccessAndNotifications,
    LabTabDataManagement,
  },
})
export default class Lab extends Vue {
  private tabs = [
    { name: "Members" },
    { name: "Data Access & Notifications" },
    { name: "Data Management" },
  ]

  get lab() {
    const labs = readLabs(this.$store);
    if (!labs) {
      return;
    }

    return labs.find(lab => lab.id === this.$route.params.id);
  }
}
</script>
