<template>
  <v-app>
    <v-container fluid class="my-4 google-font">
      <v-layout row wrap justify-center align-stretch>
        <v-flex xs12 sm9 md6 lg5 xl4 class="mb-4">
          <v-card class="mx-3 pa-4 border-top border-top-orange full-height">
            <v-layout column justify-space-between align-center class="full-height">
              <v-flex>
                <v-card-title class="my-2">
                  Archived Publication Analysis
                </v-card-title>
                <v-card-text
                  class="font-weight-regular pt-0 grey--text text--darken-2"
                >
                  Access components of BugSeq's analysis, as published:
                  <ul class="mb-5">
                    <li class="mt-2 mb-3">
                      <div>
                        Nanopore metagenomic read-based classifier with Krona output
                      </div>
                      <div class="caption">
                        Fan, Huang, Chorlton, 2021: <a href="https://doi.org/10.1186/s12859-021-04089-5" target="_blank">https://doi.org/10.1186/s12859-021-04089-5</a>
                      </div>
                    </li>
                    <li class="mb-3">
                      <div>
                        refMLST outbreak analysis
                      </div>
                      <div class="caption">
                        Khdhiri et al: <a href="https://doi.org/10.1101/2023.06.12.544669" target="_blank">https://doi.org/10.1101/2023.06.12.544669</a>
                      </div>
                    </li>
                  </ul>
                  Limitations:
                  <ul class="mt-2">
                    <li>
                      30 days of data retention
                    </li>
                    <li>
                      All results are available publicly
                    </li>
                    <li>
                      1 Gbp submission limit
                    </li>
                    <li>
                      1 submission per-day
                    </li>
                  </ul>
                </v-card-text>
              </v-flex>
              <v-flex shrink>
                <v-btn
                  outline
                  class="text-capitalize"
                  color="primary"
                  target="_blank"
                  href="/archived-publication-analysis"
                >
                  Submit Data
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs12 sm9 md6 lg5 xl4 class="mb-4">
          <v-card class="mx-3 pa-4 border-top border-top-blue full-height">
            <v-layout column justify-space-between align-center class="full-height">
              <v-flex>
                <v-card-title class="my-2">
                  Analysis for Academic
                </v-card-title>
                <v-card-text
                  class="font-weight-regular pt-0 grey--text text--darken-2"
                >
                Access BugSeq's <b>newest</b> pipelines to address all your research needs, including:
                <ul class="mt-2">
                  <li>Up to 10 samples per week</li>
                  <li>No file size limits</li>
                  <li>Support for all major sequencing platforms</li>
                  <li>Unlimited data retention</li>
                  <li>DNA/RNA/cDNA Analysis</li>
                  <li>Leading accuracy analysis with the latest BugSeq version</li>
                  <li>Basecalling support</li>
                  <li>Antimicrobial resistance (AMR) prediction</li>
                  <li>Choice of database</li>
                  <li>6 hour turnaround time</li>
                  <li>Support to answer any questions you may have about the results</li>
                </ul>
                </v-card-text>
              </v-flex>
              <v-flex shrink>
                <v-btn
                  class="text-capitalize"
                  color="primary"
                  @click="redirectToSignUp()"
                >
                  Free Trial
                </v-btn>
                <v-btn
                  class="text-capitalize"
                  color="primary"
                  target="_blank"
                  :href="quoteUrl"
                >
                  Get Quote
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { getWwwUrl } from '@bugseq-site/shared/src/env'

@Component({
  metaInfo() {
    return {
      title: "Academic",
    };
  },
})
export default class FreeLandingPage extends Vue {
  private quoteUrl: string = `${getWwwUrl()}/quote`
  private redirectToSignUp() {
    this.$router.push("/register")
  }
}
</script>

<style scoped>
.border-top {
  border-top: 6px solid;
}

.border-top-orange {
  border-color: #e65100; /* orange darken-4 */
}

.border-top-blue {
  border-color: #1976d2; /* blue darken-2 */
}

.full-height {
  height: 100%;
}
</style>
