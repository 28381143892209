<template>
  <div>
    <div
      :class="['upload-drag-drop', dropboxHighlight ? 'dropbox-highlight' : '']"
      @dragenter="highlight()"
      @dragover="highlight()"
      @dragleave="unhighlight()"
      @dragexit="unhighlight()"
      @drop="onDrop"
      @click="onPickFiles"
    >
      <input
        type="file"
        name="fileupload"
        id="fileupload"
        style="display: none;"
        ref="fileupload"
        @change="upload"
        multiple
      />
      <div class="upload-text-wrapper">
        <v-icon x-large class="mb-2" v-if="!dropboxHighlight"
          >file_upload</v-icon
        >
        <div class="upload-text" v-if="!dropboxHighlight">
          Drag and drop your files/folders here to upload, or click to select
          files
        </div>
        <div class="drop-text" v-if="dropboxHighlight">
          Drop to upload
        </div>
      </div>
      <v-progress-linear
        v-model="uploadProgress"
        v-if="uploader.inProgress.length || uploader.succeeded.length"
      ></v-progress-linear>
    </div>
    <v-expansion-panel
      expand
      class="mt-4"
      v-if="
        uploader.inProgress.length ||
        uploader.succeeded.length ||
        uploader.failed.length
      "
    >
      <v-expansion-panel-content lazy v-if="uploader.inProgress.length">
        <template v-slot:header>
          <v-layout align-center>
            In Progress
            <v-chip color="blue lighten-4" class="ml-3">{{
              uploader.inProgress.length
            }}</v-chip>
            <v-progress-circular
              indeterminate
              color="primary"
              class="ml-3"
              :size="30"
            ></v-progress-circular>
          </v-layout>
        </template>
        <v-card>
          <v-card-text>
            <v-data-table
              :headers="inProgressHeaders"
              :items="
                uploader.inProgress.map((s) => ({
                  name: s.fileMeta.name,
                  size: s.fileMeta.size,
                }))
              "
            >
              <template v-slot:items="props">
                <td>{{ props.item.name }}</td>
                <td class="text-xs-left">{{ props.item.size | formatSize }}</td>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-expansion-panel-content>
      <v-expansion-panel-content lazy v-if="uploader.succeeded.length">
        <template v-slot:header>
          <v-layout align-center>
            Succeeded
            <v-chip color="green lighten-4" class="ml-3">{{
              uploader.succeeded.length
            }}</v-chip>
          </v-layout>
        </template>
        <v-card>
          <v-card-text>
            <v-data-table
              :headers="successHeaders"
              :items="
                uploader.succeeded.map((s) => ({
                  id: s.id,
                  name: s.fileMeta.name,
                  size: s.fileMeta.size,
                }))
              "
            >
              <template v-slot:items="props">
                <td>{{ props.item.name }}</td>
                <td class="text-xs-left">{{ props.item.size | formatSize }}</td>
                <td class="text-xs-left">
                  <v-icon small @click="deleteSucceededFile(props.item.id)">
                    delete
                  </v-icon>
                </td>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-expansion-panel-content>
      <v-expansion-panel-content v-if="uploader.failed.length">
        <template v-slot:header>
          <v-layout align-center>
            Failed
            <v-chip color="red lighten-4" class="ml-3">{{
              uploader.failed.length
            }}</v-chip>
          </v-layout>
        </template>
        <v-card>
          <v-card-text>
            <v-data-table
              :headers="failedHeaders"
              :items="
                uploader.failed.map((s) => ({
                  id: s.id,
                  name: s.fileMeta.name,
                  size: s.fileMeta.size,
                }))
              "
            >
              <template v-slot:items="props">
                <td>{{ props.item.name }}</td>
                <td class="text-xs-left">{{ props.item.size | formatSize }}</td>
                <td class="text-xs-left">
                  <v-icon small @click="deleteFailedFile(props.item.id)">
                    delete
                  </v-icon>
                </td>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Store } from "vuex";
import { Uploader } from "@bugseq-site/app/src/lib/upload";
import { formatSize } from "@bugseq-site/shared/src/lib/utils";

const DropzoneProps = Vue.extend({
  props: {
    uploader: {
      type: Uploader,
      required: true,
    }
  }
})

@Component({
  filters: { formatSize },
})
export default class Dropzone extends DropzoneProps {
  public dropboxHighlight: boolean = false;
  public lockUnhighlight: boolean = false;

  private inProgressHeaders = [
    { text: "Name", value: "name" },
    { text: "Size", value: "size" },
  ];
  private successHeaders = [
    { text: "Name", value: "name" },
    { text: "Size", value: "size" },
  ];

  // weirdly, vuetify uses "name" as the value: https://v15.vuetifyjs.com/en/components/data-tables
  private failedHeaders = [
    { text: "Name", value: "name" },
    { text: "Size", value: "size" },
    { text: "Actions", value: "name", sortable: false },
  ];

  constructor() {
    super();

    // prevent browser from hijacking drop events
    // https://stackoverflow.com/a/6756680
    window.addEventListener(
      "dragover",
      (e) => {
        e = e || event;
        e.preventDefault();
      },
      false,
    );
    window.addEventListener(
      "drop",
      (e) => {
        e = e || event;
        e.preventDefault();
      },
      false,
    );
  }

  public highlight() {
    this.dropboxHighlight = true;
  }

  public unhighlight() {
    if (this.lockUnhighlight) {
      return;
    }

    this.dropboxHighlight = false;
  }

  // https://stackoverflow.com/a/11410455
  public traverseFileTree(item, path?) {
    path = path || "";
    path += item.name;

    if (item.isFile) {
      // Get file
      item.file((file) => {
        this.uploader.upload(file, path);
      });
    } else if (item.isDirectory) {
      // Get folder contents
      const dirReader = item.createReader();

      // https://stackoverflow.com/a/23823587
      const readEntries = () => {
        dirReader.readEntries((entries) => {
          for (const entry of entries) {
            this.traverseFileTree(entry, path + "/");
          }

          if (entries.length > 0) {
            readEntries();
          }
        });
      };

      readEntries();
    }
  }

  public onDrop(e) {
    e.stopPropagation();
    e.preventDefault();

    this.lockUnhighlight = true;

    const items = e.dataTransfer.items;

    for (const item of items) {
      const i = item.webkitGetAsEntry();
      if (i) {
        this.traverseFileTree(i);
      }
    }

    this.lockUnhighlight = false;

    this.unhighlight();
  }

  public onPickFiles() {
    (this.$refs.fileupload as HTMLElement).click();
  }

  public upload(e) {
    for (const file of e.target.files) {
      this.uploader.upload(file, file.name);
    }
  }

  public deleteSucceededFile(id) {
    this.uploader.deleteSucceeded(id);
  }

  public deleteFailedFile(id) {
    this.uploader.deleteFailed(id);
  }

  public get uploadProgress() {
    const inProgress = this.uploader.inProgress
      .map((i) => i.fileMeta.uploaded)
      .reduce((partialSum, a) => partialSum + a, 0);
    const succeeded = this.uploader.succeeded
      .map((i) => i.fileMeta.uploaded)
      .reduce((partialSum, a) => partialSum + a, 0);
    const total = this.uploader.inProgress
      .concat(this.uploader.succeeded)
      .map((i) => i.fileMeta.size)
      .reduce((partialSum, a) => partialSum + a, 0);
    return ((inProgress + succeeded) / total) * 100;
  }
}
</script>

<style>
.upload-drag-drop {
  width: 100%;
  height: 1px; /* https://stackoverflow.com/a/21836870 */
  min-height: 200px;
  background: #eeeeee;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.upload-drag-drop.dropbox-highlight {
  background: #424242;
}

.upload-drag-drop .v-progress-linear {
  margin: 0;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.upload-text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.upload-text {
  max-width: 300px;
  text-align: center;
}

.drop-text {
  color: white;
}
</style>
