<template>
  <v-container fluid>
    <v-layout align-center justify-center class="google-font fill-height">
      <v-flex xs12 sm8 lg6>
        <div v-if="loginStep === LoginStep.Main">
          <LoginMain :goToStep="goToStep" />
        </div>
        <div v-if="loginStep === LoginStep.MFA">
          <LoginMFA :goToStep="goToStep" />
        </div>
        <div v-if="loginStep === LoginStep.PasswordResetRequired">
          <LoginPasswordResetRequired />
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { getCurrentUser } from 'aws-amplify/auth';
import LoginMain from "@bugseq-site/app/src/components/app/LoginMain.vue";
import LoginMFA from "@bugseq-site/app/src/components/app/LoginMFA.vue";
import LoginPasswordResetRequired from "@bugseq-site/app/src/components/app/LoginPasswordResetRequired.vue";
import { LoginStep } from "@bugseq-site/app/src/components/app/LoginTypes";

@Component({
  metaInfo() {
    return {
      title: "Login",
    };
  },
  components: {
    LoginMain,
    LoginMFA,
    LoginPasswordResetRequired,
  },
  data() {
    return {
      LoginStep,
    };
  },
})
export default class LoginView extends Vue {
  private loginStep: LoginStep = LoginStep.Main;

  private async mounted() {
    try {
      const user = await getCurrentUser();

      // user is already auth'd. make sure they've signed up for bugseq by sending to /cognito
      this.redirectAfterSignIn()
    } catch (error) {
      // this is expected, they're not logged in
    }
  }

  private goToStep(step: LoginStep) {
    this.loginStep = step

    if (step === LoginStep.Done) {
      this.redirectAfterSignIn()
    }
  }

  private redirectAfterSignIn() {
    const redirect = this.$route.query.redirect || "/app/main/dashboard"
    this.$router.push({ path: "/app/cognito", query: { ...this.$route.query, redirect } })
  }
}
</script>
