<template>
  <v-card class="elevation-12 mt-4">
    <v-toolbar dark color="primary">
      <v-toolbar-title>Register</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-card-text>
      <v-form ref="form" lazy-validation>
        <v-text-field
          class="required"
          label="Email"
          name="Email"
          v-model="email"
          v-validate="'required|email|institutionEmail'"
          :error-messages="getError(fields, errors, 'Email')"
        ></v-text-field>
        <v-text-field
          class="required"
          label="Password"
          name="Password"
          type="password"
          v-model="password"
          v-validate="'required|password'"
          :error-messages="getError(fields, errors, 'Password')"
        ></v-text-field>
        <div v-if="errorMessage" class="red--text">{{ errorMessage }}</div>
        <v-btn
          @click="goToLogin"
          color="primary"
          class="text-capitalize mx-0"
          v-if="showLoginRedirect"
        >
          Go to Login
        </v-btn>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        @click="submit"
        class="text-capitalize"
        :disabled="errors.items.length > 0 || inProgress || showLoginRedirect"
      >
        Next
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import { PropType } from "vue";
import { Component, Vue } from "vue-property-decorator";
import { signUp } from 'aws-amplify/auth';

const ComponentProps = Vue.extend({
  props: {
    next: Function as PropType<() => void>,
  },
});

@Component({
  methods: {
    getError: (fields, errors, field) => {
      if (!(field in fields)) {
        return "";
      }
      if (!fields[field].dirty) {
        return "";
      }
      return errors.collect(field);
    },
  },
})
export default class RegisterEmail extends ComponentProps {
  public email: string = "";
  public password: string = "";
  public errorMessage: string = "";
  public showLoginRedirect: boolean = false;

  public inProgress = false;

  public async submit(event) {
    event.preventDefault()
    if (await this.$validator.validateAll()) {
      this.inProgress = true;

      try {
        const { isSignUpComplete, userId, nextStep } = await signUp({
          username: this.email,
          password: this.password,
          options: {
            userAttributes: {
              email: this.email,
            },
            autoSignIn: true
          }
        });
        this.errorMessage = "";
        if (nextStep.signUpStep !== "CONFIRM_SIGN_UP") {
          throw new Error(`Unrecognized next step: ${nextStep.signUpStep}`)
        }

        this.$router.push({ query: { ...this.$route.query, step: "confirm", email: this.email } })
        this.next()
      } catch (error) {
        if (error instanceof Error) {
          if (error.name === "UsernameExistsException") {
            this.errorMessage = "An account with this email already exists."
            this.showLoginRedirect = true;
            return
          }
          let errorMessage = error.message

          // cognito triggers add some pre-text to the error
          errorMessage = errorMessage.replace("PreSignUp failed with error ", "")
          if (errorMessage.includes("SSO")) {
            this.showLoginRedirect = true;
          }
          this.errorMessage = errorMessage
        } else {
          console.error("could not parse error", error)
          this.errorMessage = "Unknown error"
        }
      } finally {
        this.inProgress = false;
      }
    }
  }

  public goToLogin() {
    const query = this.$route.query
    query.step = []
    this.$router.push({ path: "/login", query })
  }
}
</script>

<style scoped>
.required >>> label::after {
  content: " *";
  color: red;
}
</style>
