export class Queue<Type> {
  private readonly items: Type[]

  constructor () {
    this.items = []
  }

  public enqueue (item: Type): void {
    this.items.push(item)
  }

  public dequeue (): Type | undefined {
    return this.items.shift()
  }

  get length (): number {
    return this.items.length
  }
}
