var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{class:['upload-drag-drop', _vm.dropboxHighlight ? 'dropbox-highlight' : ''],on:{"dragenter":function($event){return _vm.highlight()},"dragover":function($event){return _vm.highlight()},"dragleave":function($event){return _vm.unhighlight()},"dragexit":function($event){return _vm.unhighlight()},"drop":_vm.onDrop,"click":_vm.onPickFiles}},[_c('input',{ref:"fileupload",staticStyle:{"display":"none"},attrs:{"type":"file","name":"fileupload","id":"fileupload","multiple":""},on:{"change":_vm.upload}}),_c('div',{staticClass:"upload-text-wrapper"},[(!_vm.dropboxHighlight)?_c('v-icon',{staticClass:"mb-2",attrs:{"x-large":""}},[_vm._v("file_upload")]):_vm._e(),(!_vm.dropboxHighlight)?_c('div',{staticClass:"upload-text"},[_vm._v(" Drag and drop your files/folders here to upload, or click to select files ")]):_vm._e(),(_vm.dropboxHighlight)?_c('div',{staticClass:"drop-text"},[_vm._v(" Drop to upload ")]):_vm._e()],1),(_vm.uploader.inProgress.length || _vm.uploader.succeeded.length)?_c('v-progress-linear',{model:{value:(_vm.uploadProgress),callback:function ($$v) {_vm.uploadProgress=$$v},expression:"uploadProgress"}}):_vm._e()],1),(
      _vm.uploader.inProgress.length ||
      _vm.uploader.succeeded.length ||
      _vm.uploader.failed.length
    )?_c('v-expansion-panel',{staticClass:"mt-4",attrs:{"expand":""}},[(_vm.uploader.inProgress.length)?_c('v-expansion-panel-content',{attrs:{"lazy":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('v-layout',{attrs:{"align-center":""}},[_vm._v(" In Progress "),_c('v-chip',{staticClass:"ml-3",attrs:{"color":"blue lighten-4"}},[_vm._v(_vm._s(_vm.uploader.inProgress.length))]),_c('v-progress-circular',{staticClass:"ml-3",attrs:{"indeterminate":"","color":"primary","size":30}})],1)]},proxy:true}],null,false,2980062621)},[_c('v-card',[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.inProgressHeaders,"items":_vm.uploader.inProgress.map((s) => ({
                name: s.fileMeta.name,
                size: s.fileMeta.size,
              }))},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('td',[_vm._v(_vm._s(props.item.name))]),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(_vm._f("formatSize")(props.item.size)))])]}}],null,false,3745643179)})],1)],1)],1):_vm._e(),(_vm.uploader.succeeded.length)?_c('v-expansion-panel-content',{attrs:{"lazy":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('v-layout',{attrs:{"align-center":""}},[_vm._v(" Succeeded "),_c('v-chip',{staticClass:"ml-3",attrs:{"color":"green lighten-4"}},[_vm._v(_vm._s(_vm.uploader.succeeded.length))])],1)]},proxy:true}],null,false,702841352)},[_c('v-card',[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.successHeaders,"items":_vm.uploader.succeeded.map((s) => ({
                id: s.id,
                name: s.fileMeta.name,
                size: s.fileMeta.size,
              }))},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('td',[_vm._v(_vm._s(props.item.name))]),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(_vm._f("formatSize")(props.item.size)))]),_c('td',{staticClass:"text-xs-left"},[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteSucceededFile(props.item.id)}}},[_vm._v(" delete ")])],1)]}}],null,false,958128794)})],1)],1)],1):_vm._e(),(_vm.uploader.failed.length)?_c('v-expansion-panel-content',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('v-layout',{attrs:{"align-center":""}},[_vm._v(" Failed "),_c('v-chip',{staticClass:"ml-3",attrs:{"color":"red lighten-4"}},[_vm._v(_vm._s(_vm.uploader.failed.length))])],1)]},proxy:true}],null,false,1079831296)},[_c('v-card',[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.failedHeaders,"items":_vm.uploader.failed.map((s) => ({
                id: s.id,
                name: s.fileMeta.name,
                size: s.fileMeta.size,
              }))},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('td',[_vm._v(_vm._s(props.item.name))]),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(_vm._f("formatSize")(props.item.size)))]),_c('td',{staticClass:"text-xs-left"},[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteFailedFile(props.item.id)}}},[_vm._v(" delete ")])],1)]}}],null,false,2998315898)})],1)],1)],1):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }