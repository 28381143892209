import Router from 'vue-router'

export async function redirectToSignIn (router: Router, redirect: string = '/app/main/dashboard'): Promise<void> {
  try {
    await router.push({ path: '/login', query: { redirect } })
  } catch (e) {
    // this will happen if a page loads and fires multiple reqs
    // and they all cause a redirect to login
  }
}
