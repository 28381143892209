<template>
  <Submitted />
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Submitted from "@bugseq-site/app/src/components/app/Submitted.vue";

@Component({
  metaInfo: {
    title: "Submitted",
  },
  components: { Submitted },
})
export default class SubmittedView extends Vue {}
</script>
