<template>
  <div>
    Click a BaseSpace project to see and add samples from that project
    <v-data-table
      :headers="projectHeaders"
      :items="projects"
      :total-items="projectsTotalCount"
      :loading="projectsLoading"
      :pagination.sync="projectPagination"
      :rows-per-page-items="projectRowsPerPageItems"
      must-sort
      :expand="false"
      item-key="Id"
    >
      <template v-slot:items="props">
        <tr
          @click="expand(props)"
          :class="{
            ['table-row']: true,
            grey: props.expanded,
            ['lighten-5']: props.expanded,
          }"
        >
          <td>
            {{ props.item.Name }}
            <div class="error--text" v-if="props.item.TotalSize <= 0">
              Project unavailable because it has no data
            </div>
          </td>
          <td>{{ props.item.DateModified | formatTime }}</td>
          <td>
            <v-layout row>
              <v-btn
                v-if="props.expanded"
                flat
                outline
                :disabled="
                  uploader.inProgress.some(
                    (a) => a.baseSpaceProjectId === props.item.Id,
                  )
                "
                @click="selectAll($event, props.item.Id, props.item.Name)"
                class="add-all text-capitalize"
                >Select All ({{projectSamples.filter(checkValidSample).length}})</v-btn
              >
              <v-btn
                v-if="props.expanded"
                flat
                outline
                @click="toggleShowSampleSearch($event)"
                class="add-all text-capitalize"
                ><v-icon>search</v-icon></v-btn
              >
            </v-layout>
          </td>
        </tr>
      </template>
      <template v-slot:expand="props">
        <div class="sample-table grey lighten-5">
          <v-layout align-center class="ml-5">
            <v-spacer></v-spacer>
            <v-text-field
              v-if="showSampleSearch"
              v-model="sampleSearch"
              append-icon="search"
              label="Sample Name (must be exact)"
              single-line
              hide-details
              class="mr-4 mt-0 mb-2 sample-search-input"
            ></v-text-field>
          </v-layout>
          <v-data-table
            :headers="sampleHeaders"
            :items="projectSamples"
            :total-items="projectSamplesTotalCount"
            :loading="projectSamplesLoading"
            :pagination.sync="samplePagination"
            :rows-per-page-items="sampleRowsPerPageItems"
            must-sort
            expand
            item-key="Id"
            class="ml-5"
          >
            <template v-slot:items="sample">
              <tr class="grey lighten-5">
                <td>{{ sample.item.BioSampleName }}</td>
                <td>{{ sample.item.DateModified | formatTime }}</td>
                <td class="text-xs-center">
                  <v-btn
                    flat
                    icon
                    v-if="
                      !uploader.succeeded.some(
                        (a) => a.basespaceSampleId === sample.item.Id,
                      ) && checkValidSample(sample.item)
                    "
                    :disabled="
                      uploader.inProgress.some(
                        (a) => a.basespaceSampleId === sample.item.Id,
                      )
                    "
                    @click="
                      select(
                        props.item.Id,
                        props.item.Name,
                        sample.item.Id,
                        sample.item.BioSampleName,
                      )
                    "
                    ><v-icon>add</v-icon></v-btn
                  >
                  <PopOut
                    v-else-if="!checkValidSample(sample.item)"
                    icon="warning"
                    icon-color="orange"
                  >No datasets were found for this sample in BaseSpace</PopOut>
                  <v-icon v-else class="green--text">check</v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </template>
    </v-data-table>
    <v-expansion-panel
      expand
      class="mt-4"
      v-if="
        uploader.inProgress.length ||
        uploader.succeeded.length ||
        uploader.failed.length
      "
    >
      <v-expansion-panel-content lazy v-if="uploader.inProgress.length">
        <template v-slot:header>
          <v-layout align-center>
            In Progress
            <v-chip color="blue lighten-5" class="ml-3">{{
              uploader.inProgress.length
            }}</v-chip>
            <v-progress-circular
              indeterminate
              color="primary"
              class="ml-3"
              :size="30"
            ></v-progress-circular>
          </v-layout>
        </template>
        <v-card>
          <v-card-text>
            <v-data-table
              :headers="inProgressHeaders"
              :items="
                uploader.inProgress.map((s) => ({
                  name: s.basespaceSampleName,
                }))
              "
            >
              <template v-slot:items="props">
                <td>{{ props.item.basespaceSampleName }}</td>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-expansion-panel-content>
      <v-expansion-panel-content lazy v-if="uploader.succeeded.length">
        <template v-slot:header>
          <v-layout align-center>
            Succeeded
            <v-chip color="green lighten-5" class="ml-3">{{
              uploader.succeeded.length
            }}</v-chip>
          </v-layout>
        </template>
        <v-card>
          <v-card-text>
            <v-data-table :headers="successHeaders" :items="uploader.succeeded">
              <template v-slot:items="props">
                <td>
                  {{ props.item.basespaceProjectName }}:
                  {{ props.item.basespaceSampleName }}
                </td>
                <td class="text-xs-center">
                  <v-icon
                    small
                    @click="deleteSucceededFile(props.item.basespaceSampleId)"
                  >
                    delete
                  </v-icon>
                </td>
              </template>
              <template v-slot:footer>
                <td></td>
                <td class="text-xs-center">
                  <v-btn
                    class="text-capitalize error error--text"
                    outline
                    flat
                    @click="deleteAllSucceededFiles()"
                    >Deselect All</v-btn
                  >
                </td>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-expansion-panel-content>
      <v-expansion-panel-content v-if="uploader.failed.length">
        <template v-slot:header>
          <v-layout align-center>
            Failed
            <v-chip color="red lighten-5" class="ml-3">{{
              uploader.failed.length
            }}</v-chip>
          </v-layout>
        </template>
        <v-card>
          <v-card-text>
            <v-data-table :headers="failedHeaders" :items="uploader.failed">
              <template v-slot:items="props">
                <td>{{ props.item.basespaceSampleName }}</td>
                <td class="text-xs-left">
                  <v-icon
                    small
                    @click="deleteFailedFile(props.item.basespaceSampleId)"
                  >
                    delete
                  </v-icon>
                </td>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { components, operations } from '@bugseq-site/app/src/lib/api/api'
import { BasespaceSampleUploader } from "@bugseq-site/app/src/lib/basespace";
import {
  dispatchGetBasespaceProjects,
  dispatchGetBasespaceSamples,
} from "@bugseq-site/app/src/store/modules/api/actions";
import PopOut from "@bugseq-site/shared/src/components/PopOut.vue";
import { formatTime } from "@bugseq-site/shared/src/lib/utils";

const BasespaceSamplePickerProps = Vue.extend({
  props: {
    uploader: {
      type: BasespaceSampleUploader,
      required: true,
    }
  }
})

@Component({
  filters: {
    formatTime,
  },
  components: {
    PopOut,
  },
})
export default class BasespaceSamplePicker extends BasespaceSamplePickerProps {
  public projectHeaders = [
    {
      text: "Name",
      value: "Name",
      sortable: true,
    },
    {
      text: "Modified",
      value: "DateModified",
      sortable: true,
      width: "30%",
    },
    {
      text: "Actions",
      value: "",
      sortable: false,
      align: "center",
      width: "1%",
    },
  ];

  public sampleHeaders = [
    {
      text: "Sample Name",
      value: "BioSampleName",
      sortable: true,
      class: ["grey", "lighten-5"],
    },
    {
      text: "Modified",
      value: "DateModified",
      sortable: true,
      width: "30%",
      class: ["grey", "lighten-5"],
    },
    {
      text: "Add",
      value: "",
      sortable: false,
      align: "center",
      width: "1%",
      class: ["grey", "lighten-5"],
    },
  ];

  private projectRowsPerPageItems: number[] = [10, 20, 50];
  public projectPagination: {
    sortBy: Required<operations['projects_v1_basespace_projects_get']['parameters']>['query']['sort_by']
    descending: boolean,
    page: number,
    rowsPerPage: number,
  } = {
    sortBy: "DateModified",
    descending: true,
    page: 1,
    rowsPerPage: this.projectRowsPerPageItems[0],
  };
  private projects: components['schemas']['BasespaceProject'][] = []
  private projectsTotalCount: number | null = null;
  private projectsLoading: boolean = false;
  private expandedProjectId: string | null = null;

  private sampleRowsPerPageItems: number[] = [10, 20, 50];
  public samplePagination: {
    sortBy: Required<operations['samples_v1_basespace_projects__project_id__samples_get']['parameters']>['query']['sort_by']
    descending: boolean,
    page: number,
    rowsPerPage: number,
  } = {
    sortBy: "DateModified",
    descending: true,
    page: 1,
    rowsPerPage: this.sampleRowsPerPageItems[0],
  };
  private projectSamples: components['schemas']['BasespaceSample'][] = []
  private projectSamplesTotalCount: number | null = null;
  private projectSamplesLoading: boolean = false;
  private showSampleSearch: boolean = false;
  private sampleSearch: string = '';

  private toggleShowSampleSearch(event) {
    event.stopPropagation();
    this.showSampleSearch = !this.showSampleSearch
  }

  private inProgressHeaders = [{ text: "Name", value: "name" }];
  private successHeaders = [{ text: "Name", value: "name" }];

  // weirdly, vuetify uses "name" as the value: https://v15.vuetifyjs.com/en/components/data-tables
  private failedHeaders = [
    { text: "Name", value: "name" },
    { text: "Actions", value: "name", sortable: false },
  ];

  @Watch("projectPagination", { deep: true })
  private async projectPaginationChanged(pagination) {
    const { sortBy, descending, page, rowsPerPage } = pagination;
    const sortDir: Required<operations['projects_v1_basespace_projects_get']['parameters']>['query']['sort_dir'] = descending ? 'desc' : 'asc';

    const opts = {
      sort_by: sortBy,
      sort_dir: sortDir,
      limit: rowsPerPage,
      skip: (page - 1) * rowsPerPage,
    };

    this.projectsLoading = true
    const resp: components['schemas']['ListBasespaceProjectsResponse'] = await dispatchGetBasespaceProjects(this.$store, opts);
    this.projects = resp.items
    this.projectsTotalCount = resp.total_count
    this.projectsLoading = false
  }

  @Watch("sampleSearch")
  private async sampleSearchChanged() {
    return this.samplePaginationChanged(this.samplePagination);
  }

  @Watch("samplePagination", { deep: true })
  private async samplePaginationChanged(pagination) {
    const { sortBy, descending, page, rowsPerPage } = pagination;
    const sortDir: Required<operations['samples_v1_basespace_projects__project_id__samples_get']['parameters']>['query']['sort_dir'] = descending ? 'desc' : 'asc';

    const opts: {
      projectId: string,
      query: Required<operations['samples_v1_basespace_projects__project_id__samples_get']['parameters']>['query'],
    } = {
      projectId: this.expandedProjectId!,
      query: {
        sort_by: sortBy,
        sort_dir: sortDir,
        limit: rowsPerPage,
        skip: (page - 1) * rowsPerPage,
      },
    };

    if (this.sampleSearch) {
      opts.query.biosamplename = this.sampleSearch
    }

    this.projectSamplesLoading = true;
    const resp: components['schemas']['ListBasespaceSamplesResponse'] = await dispatchGetBasespaceSamples(this.$store, opts);
    this.projectSamples = resp.items
    this.projectSamplesTotalCount = resp.total_count
    this.projectSamplesLoading = false;
  }

  public expand(props) {
    if (props.item.TotalSize <= 0) {
      return;
    }

    props.expanded = !props.expanded;

    if (props.expanded) {
      this.expandedProjectId = props.item.Id;
    }
  }

  public select(
    projectId: string,
    projectName: string,
    sampleId: string,
    sampleName: string,
  ) {
    this.uploader.upload(projectId, projectName, sampleId, sampleName);
  }

  public checkValidSample(sample: components['schemas']['BasespaceSample']): boolean {
    return sample.dataset_count > 0
  }

  public selectAll(event, projectId: string, projectName: string) {
    event.stopPropagation();
    for (const sample of this.projectSamples) {
      if (
        !this.uploader.succeeded
          .concat(this.uploader.inProgress)
          .some((a) => a.basespaceSampleId === sample.Id)
        && this.checkValidSample(sample)
      ) {
        this.select(projectId, projectName, sample.Id, sample.BioSampleName);
      }
    }
  }

  public deleteAllSucceededFiles() {
    for (const succeeded of this.uploader.succeeded) {
      this.uploader.deleteSucceeded(succeeded.basespaceSampleId);
    }
  }

  public deleteSucceededFile(id) {
    this.uploader.deleteSucceeded(id);
  }

  public deleteFailedFile(id) {
    this.uploader.deleteFailed(id);
  }
}
</script>

<style>
.table-row {
  cursor: pointer;
}

.add-all {
  font-size: 13px;
  font-weight: 400;
}

.sample-table .v-datatable__actions {
  background: #fafafa !important; /* matches lighten-5 */
}

.sample-search-input {
  max-width: 280px;
}
</style>
