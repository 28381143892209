<template>
  <LabNew />
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import LabNew from "@bugseq-site/app/src/components/app/LabNew.vue";

@Component({
  components: {
    LabNew,
  },
  metaInfo: {
    title: "Lab",
  },
})
export default class LabNewPage extends Vue {}
</script>
