import { components } from '@bugseq-site/app/src/lib/api/api'

export function parseUser (u: components['schemas']['UserResponse']): components['schemas']['UserResponse'] {
  return {
    ...u,
    created: new Date(u.created)
  }
}

export function parseJobRunResponse (d: components['schemas']['JobRunResponse']): components['schemas']['JobRunResponse'] {
  return {
    ...d,
    created: new Date(d.created),
    end_time: (d.end_time != null) ? new Date(d.end_time) : null
  }
}

export function parseJobRunResultsResponse (r: components['schemas']['JobRunResultsResponse']): components['schemas']['JobRunResultsResponse'] {
  return {
    ...r,
    created: new Date(r.created),
    end_time: (r.end_time != null) ? new Date(r.end_time) : null
  }
}

function parseBasespaceSample (p: components['schemas']['BasespaceSample']): components['schemas']['BasespaceSample'] {
  return {
    ...p,
    DateCreated: new Date(p.DateCreated),
    DateModified: new Date(p.DateModified)
  }
}

export function parseListBasespaceSamplesResponse (r: components['schemas']['ListBasespaceSamplesResponse']): components['schemas']['ListBasespaceSamplesResponse'] {
  return {
    ...r,
    items: r.items.map(parseBasespaceSample)
  }
}

function parseBasespaceProject (p: components['schemas']['BasespaceProject']): components['schemas']['BasespaceProject'] {
  return {
    ...p,
    DateCreated: new Date(p.DateCreated),
    DateModified: new Date(p.DateModified)
  }
}

export function parseListBasespaceProjectsResponse (r: components['schemas']['ListBasespaceProjectsResponse']): components['schemas']['ListBasespaceProjectsResponse'] {
  return {
    ...r,
    items: r.items.map(parseBasespaceProject)
  }
}

export function parseAnalysisDataSummaryResponse (a: components['schemas']['AnalysisDataSummaryResponse']): components['schemas']['AnalysisDataSummaryResponse'] {
  return {
    ...a,
    job_run_end_time: new Date(a.job_run_end_time)
  }
}

export function parseInviteResponse (a: components['schemas']['InviteResponse']): components['schemas']['InviteResponse'] {
  return {
    ...a,
    created: new Date(a.created),
    expires: new Date(a.expires),
    accepted: (a.accepted !== null) ? new Date(a.accepted) : null
  }
}

export function parseBillingAccountSampleCreditResponse (r: components['schemas']['BillingAccountSampleCreditResponse']): components['schemas']['BillingAccountSampleCreditResponse'] {
  return {
    ...r,
    created: new Date(r.created),
    expiration: new Date(r.expiration)
  }
}

export function parseBillingAccountUserCreditResponse (r: components['schemas']['BillingAccountUserCreditResponse']): components['schemas']['BillingAccountUserCreditResponse'] {
  return {
    ...r,
    created: new Date(r.created),
    expiration: new Date(r.expiration)
  }
}
