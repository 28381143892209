import { getStoreAccessors } from 'typesafe-vuex'
import { RootState } from '../../state'
import { DrawerState } from './state'

export const mutations = {
  setDashboardMiniDrawer (state: DrawerState, payload: boolean) {
    state.dashboardMiniDrawer = payload
  },
  setDashboardShowDrawer (state: DrawerState, payload: boolean) {
    state.dashboardShowDrawer = payload
  }
}

const { commit } = getStoreAccessors<DrawerState, RootState>('drawer')

export const commitSetDashboardMiniDrawer = commit(
  mutations.setDashboardMiniDrawer
)
export const commitSetDashboardShowDrawer = commit(
  mutations.setDashboardShowDrawer
)
