<template>
  <v-card class="elevation-12 mt-4">
    <v-toolbar dark color="primary">
      <v-toolbar-title>Login</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-card-text>
      <div class="my-3">
        A password reset is required before you can log in
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn
        block
        @click="forgotPassword"
        class="text-capitalize"
        color="primary"
      >
        Go to Reset Password
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class LoginPasswordResetRequired extends Vue {
  public forgotPassword() {
    this.$router.push({ path: "/forgot-password", query: this.$route.query })
  }
}
</script>
