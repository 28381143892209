<template>
  <JobTable />
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import JobTable from "@bugseq-site/app/src/components/app/JobTable.vue";

@Component({
  components: {
    JobTable,
  },
  metaInfo: {
    title: "History",
  },
})
export default class HistoryView extends Vue {}
</script>
