<template>
  <v-container fluid>
    <v-layout align-center justify-center class="google-font fill-height">
      <v-flex xs12 sm8 lg6>
        <v-card class="elevation-12 mt-4">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Forgot Password</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>

          <v-form lazy-validation class="px-5 py-2">
            <v-card-text>
              <v-text-field
                class="required"
                label="Email"
                name="Email"
                type="email"
                v-model="email"
                v-validate="'required'"
                :disabled="!!codeDeliveryDetails || complete"
                :error-messages="getError(fields, errors, 'Email')"
              ></v-text-field>
              <div v-if="codeDeliveryDetails" class="my-3">A confirmation code was sent to: {{ codeDeliveryDetails }}</div>
              <v-text-field
                class="required"
                label="Confirmation Code"
                name="Confirmation Code"
                v-model="confirmationCode"
                v-validate="'required'"
                v-if="codeDeliveryDetails"
                :disabled="complete"
                :error-messages="getError(fields, errors, 'Confirmation Code')"
              ></v-text-field>
              <v-text-field
                class="required"
                label="New Password"
                name="New Password"
                type="password"
                v-model="newPassword"
                v-validate="'required'"
                v-if="codeDeliveryDetails"
                :disabled="complete"
                :error-messages="getError(fields, errors, 'New Password')"
              ></v-text-field>
              <v-text-field
                class="required"
                label="Confirm New Password"
                name="Confirm New Password"
                type="password"
                v-model="confirmNewPassword"
                v-validate="'required'"
                v-if="codeDeliveryDetails"
                :disabled="complete"
                :error-messages="getError(fields, errors, 'Confirm New Password')"
              ></v-text-field>
              <div v-if="errorMessage" class="red--text">{{ errorMessage }}</div>
              <div v-if="complete">Success!</div>
            </v-card-text>
            <v-card-actions>
              <v-layout column class="mb-3">
                <v-flex xs12 v-if="!codeDeliveryDetails">
                  <v-btn
                    block
                    type="submit"
                    @click="submit"
                    class="text-capitalize"
                    color="primary"
                    :disabled="errors.items.length > 0 || inProgress || complete"
                  >
                    Reset Password
                  </v-btn>
                </v-flex>
                <v-flex xs12 v-else-if="!complete">
                  <v-btn
                    block
                    type="submit"
                    @click="confirmReset"
                    class="text-capitalize"
                    color="primary"
                    :disabled="errors.items.length > 0 || inProgress || complete"
                  >
                    Reset Password
                  </v-btn>
                </v-flex>
                <v-flex xs12 v-else>
                  <v-btn
                    block
                    @click="goToLogin"
                    class="text-capitalize"
                    color="primary"
                  >
                    Go to Log In
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { resetPassword, confirmResetPassword } from 'aws-amplify/auth';

@Component({
  metaInfo() {
    return {
      title: "Password Reset",
    };
  },
  methods: {
    getError: (fields, errors, field) => {
      if (!(field in fields)) {
        return "";
      }
      if (!fields[field].dirty) {
        return "";
      }
      return errors.collect(field);
    },
  },
})
export default class ForgotPassword extends Vue {
  private email: string = "";
  private errorMessage: string = "";

  private codeDeliveryDetails: string = ""
  private confirmationCode: string = ""
  private newPassword: string = ""
  private confirmNewPassword: string = ""

  private inProgress: boolean = false;

  private complete: boolean = false;

  private mounted() {
    this.email = (this.$route.query.email as string) || ''
  }

  public async submit(event) {
    event.preventDefault()

    if (await this.$validator.validateAll()) {
      this.inProgress = true;

      try {
        const { nextStep } = await resetPassword({
          username: this.email,
        });
        switch (nextStep.resetPasswordStep) {
          case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
            const codeDeliveryDetails = nextStep.codeDeliveryDetails;
            this.codeDeliveryDetails = codeDeliveryDetails.destination || '<unknown>'
            break;
          case 'DONE':
            this.complete = true
            break;
        }
      } catch (error) {
        if (error instanceof Error) {
          this.errorMessage = error.message
        } else {
          console.error("could not parse error", error)
          this.errorMessage = "Unknown error"
        }
      } finally {
        this.inProgress = false;
      }
    }
  }

  public async confirmReset(event) {
    event.preventDefault()

    if (await this.$validator.validateAll()) {
      this.inProgress = true;

      if (this.newPassword !== this.confirmNewPassword) {
        this.errorMessage = "Passwords do not match"
        this.inProgress = false;
        return
      }

      try {
        await confirmResetPassword({
          username: this.email,
          confirmationCode: this.confirmationCode,
          newPassword: this.newPassword,
        });
        this.complete = true
      } catch (error) {
        if (error instanceof Error) {
          this.errorMessage = error.message
        } else {
          console.error("could not parse error", error)
          this.errorMessage = "Unknown error"
        }
      } finally {
        this.inProgress = false;
      }
    }
  }

  public goToLogin() {
    this.$router.push("/login")
  }
}
</script>

<style scoped>
.required >>> label::after {
  content: " *";
  color: red;
}
</style>
