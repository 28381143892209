<template>
  <v-card class="elevation-12 mt-4">
    <v-toolbar dark color="primary">
      <v-toolbar-title>Login</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-form lazy-validation class="px-5 py-2">
      <v-card-text>
        <v-text-field
          class="required"
          label="Email"
          name="Email"
          type="email"
          v-model="email"
          v-validate="'required'"
          :error-messages="getError(fields, errors, 'Email')"
        ></v-text-field>
        <v-text-field
          v-if="!useSSO"
          class="required"
          label="Password"
          name="Password"
          type="password"
          v-model="password"
          v-validate="'required'"
          :error-messages="getError(fields, errors, 'Password')"
        ></v-text-field>
        <div v-if="errorMessage" class="red--text">{{ errorMessage }}</div>
      </v-card-text>
      <v-card-actions>
        <v-layout column class="mb-3" v-if="!useSSO">
          <v-flex xs12>
            <v-btn
              block
              type="submit"
              @click="submit"
              class="text-capitalize"
              color="primary"
              :disabled="errors.items.length > 0 || inProgress"
            >
              Log In
            </v-btn>
          </v-flex>
          <v-flex xs12 class="mt-3">
            <v-btn
              block
              v-if="errorMessage"
              @click="forgotPassword"
              class="text-capitalize"
            >
              Forgot Password
            </v-btn>
          </v-flex>
          <v-flex xs12 class="my-4" align-self-center>
            OR
          </v-flex>
          <v-flex xs12>
            <v-btn
              block
              @click="signInWithIdp"
              class="text-capitalize"
            >
              Log In with SSO
            </v-btn>
          </v-flex>
          <v-flex xs12 class="mt-3">
            <v-btn
              block
              @click="register"
              class="text-capitalize"
            >
              Register
            </v-btn>
          </v-flex>
        </v-layout>
        <v-layout column class="mb-3" v-else>
          <v-flex xs12 class="mb-3">
            <v-btn
              block
              type="submit"
              @click="signInWithIdp"
              class="text-capitalize"
              color="primary"
            >
              Log In with SSO
            </v-btn>
          </v-flex>
          <v-flex xs12>
            <v-btn
              block
              @click="disableSSO"
              class="text-capitalize"
            >
              Log In with Email/Password
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script lang="ts">
import { PropType } from "vue";
import { Component, Vue } from "vue-property-decorator";
import { signIn, signInWithRedirect } from 'aws-amplify/auth';
import { LoginStep } from "@bugseq-site/app/src/components/app/LoginTypes";

const ComponentProps = Vue.extend({
  props: {
    goToStep: Function as PropType<(step: LoginStep) => void>,
  },
});

@Component({
  methods: {
    getError: (fields, errors, field) => {
      if (!(field in fields)) {
        return "";
      }
      if (!fields[field].dirty) {
        return "";
      }
      return errors.collect(field);
    },
  },
})
export default class LoginMain extends ComponentProps {
  private email: string = "";
  private useSSO: boolean = false;
  private password: string = "";
  private errorMessage: string = "";

  private inProgress = false;

  public async submit(event) {
    event.preventDefault()

    if (await this.$validator.validateAll()) {
      this.inProgress = true;

      try {
        const { nextStep } = await signIn({
          username: this.email,
          password: this.password,
          options: {
            authFlowType: 'USER_PASSWORD_AUTH',
          },
        });
        // if the user previously registered a cognito user but never confirmed email.
        if (nextStep.signInStep === "CONFIRM_SIGN_UP") {
          const step = this.$route.query.step || "confirm"
          this.$router.push({ path: "/register", query: { ...this.$route.query, step: step, email: this.email } })
          return
        } else if (nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_TOTP_CODE") {
          try {
            await this.$router.push({ query: { ...this.$route.query, mfaopts: ["TOTP"] } })
          } catch (e) {
            // this can happen for redundant routes
            // should be safe to ignore
          }
          this.goToStep(LoginStep.MFA)
          return
        } else if (nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_SMS_CODE") {
          try {
            await this.$router.push({ query: { ...this.$route.query, mfaopts: ["SMS"] } })
          } catch (e) {
            // this can happen for redundant routes
            // should be safe to ignore
          }
          this.goToStep(LoginStep.MFA)
          return
        } else if (nextStep.signInStep === "CONTINUE_SIGN_IN_WITH_MFA_SELECTION") {
          try {
            await this.$router.push({ query: { ...this.$route.query, mfaopts: nextStep.allowedMFATypes } })
          } catch (e) {
            // this can happen for redundant routes
            // should be safe to ignore
          }
          this.goToStep(LoginStep.MFA)
          return
        } else if (nextStep.signInStep === "RESET_PASSWORD") {
          // the reset-password form uses this
          const redirect = this.$route.query.redirect
          const email = this.email
          try {
            await this.$router.push({ query: { redirect, email } })
          } catch (e) {
            // this can happen for redundant routes
            // should be safe to ignore
          }
          this.goToStep(LoginStep.PasswordResetRequired)
          return
        } else if (nextStep.signInStep !== "DONE") {
          console.error("unrecognized next step", nextStep)
          throw new Error("Unknown error")
        }

        this.goToStep(LoginStep.Done)
      } catch (error) {
        if (error instanceof Error) {
          this.errorMessage = error.message
        } else {
          console.error("could not parse error", error)
          this.errorMessage = "Unknown error"
        }
      } finally {
        this.inProgress = false;
      }
    }
  }

  public forgotPassword() {
    const redirect = this.$route.query.redirect
    const email = this.email
    this.$router.push({ path: "/forgot-password", query: { redirect, email } })
  }

  public register() {
    const params = new URLSearchParams(window.location.search);
    params.delete("step")
    this.$router.push(`/register?${params.toString()}`)
  }

  public async signInWithIdp(event) {
    event.preventDefault()

    this.useSSO = true

    if (!this.email) {
      this.errorMessage = "Enter your email address"
      return
    }

    const match = this.email.match(/@(.*)/);
    if (!match) {
      this.errorMessage = "Invalid email"
      return
    }

    const redirect = this.$route.query.redirect || "/app/main/dashboard"

    signInWithRedirect({provider: {custom: match[1]}, customState: JSON.stringify({ redirect })})
  }

  public disableSSO() {
    this.useSSO = false
    this.errorMessage = ""
  }
}
</script>

<style scoped>
.required >>> label::after {
  content: " *";
  color: red;
}
</style>
