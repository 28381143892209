<template>
  <v-container fluid justify-center d-flex>
    <v-flex class="md12 lg10 xl8 google-font">
      <v-card>
        <div class="pa-4">
          <div class="pt-2 pb-4 headline">
            Dropbox
          </div>
          <div v-if="!submitted">
            <div class="mb-4">You can use this dropbox to securely transfer files to BugSeq</div>
            <Dropzone :uploader="uploader" />
            <v-alert
              type="error"
              :value="uploader.failed.length > 0"
              class="google-font mb-4"
            >
              Some files failed to upload. Please remove them from above and retry
              or submit without them.
              <ul class="mt-3">
                <li
                  v-for="f in uploader.failed
                    .filter((f) => f.err)
                    .slice(0, 5)"
                >
                  {{ f.err }}
                </li>
              </ul>
            </v-alert>
            <v-btn
              large
              @click="submit"
              color="primary"
              :disabled="
                uploader.inProgress.length > 0 ||
                uploader.failed.length > 0 ||
                uploader.succeeded.length === 0
              "
              class="text-capitalize ml-0 mt-4"
            >
              Submit
            </v-btn>
          </div>
          <div v-else>
            We have received your submission and will be in touch.
          </div>
        </div>
      </v-card>
    </v-flex>
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Store } from "vuex";
import { readUserProfile } from "@bugseq-site/app/src/store/modules/api/getters";
import Dropzone from "@bugseq-site/app/src/components/app/Dropzone.vue";
import { Uploader } from "@bugseq-site/app/src/lib/upload";
import {
  dispatchDropboxSubmit,
} from "@bugseq-site/app/src/store/modules/api/actions";

@Component({
  components: {
    Dropzone,
  },
})
export default class Dropbox extends Vue {
  private s3Region: string;
  private uploader: Uploader;
  private submitted: boolean = false;

  constructor() {
    super();

    const userProfile = readUserProfile(this.$store);
    if (userProfile && userProfile.pinned_region) {
      this.s3Region = userProfile.pinned_region;
    } else {
      this.s3Region = "ca-central-1";
    }

    this.uploader = new Uploader(
      this.s3Region, // region
      [], // accepted extensions - accept anything
      false, // archived-publication-analysis
    );
  }

  private async submit() {
    const payload = {
      file_ids: this.uploader.succeeded.map((s) => s.bugseqId!),
    }
    await dispatchDropboxSubmit(this.$store, payload);
    this.submitted = true
  }
}
</script>
