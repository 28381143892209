<template>
  <v-container fluid>
    <v-layout align-center justify-center class="google-font fill-height">
      <v-flex xs12 sm8 lg6>
        <div v-if="registrationStep === RegistrationStep.Email">
          <RegisterEmail :next="() => goToNext(RegistrationStep.Confirm)" />
        </div>
        <div v-if="registrationStep === RegistrationStep.Confirm">
          <RegisterEmailConfirm :next="() => goToNext(RegistrationStep.MFA)" />
        </div>
        <div v-if="registrationStep === RegistrationStep.MFA">
          <RegisterMFA :next="() => goToNext(RegistrationStep.Details)" />
        </div>
        <div v-if="registrationStep === RegistrationStep.Details">
          <RegisterDetails />
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import RegisterEmail from "@bugseq-site/app/src/components/app/RegisterEmail.vue";
import RegisterEmailConfirm from "@bugseq-site/app/src/components/app/RegisterEmailConfirm.vue";
import RegisterMFA from "@bugseq-site/app/src/components/app/RegisterMFA.vue";
import RegisterDetails from "@bugseq-site/app/src/components/app/RegisterDetails.vue";

enum RegistrationStep {
  Email,
  Confirm,
  MFA,
  Details,
}

@Component({
  metaInfo() {
    return {
      title: "Registration",
    };
  },
  components: {
    RegisterEmail,
    RegisterEmailConfirm,
    RegisterMFA,
    RegisterDetails,
  },
  data() {
    return {
      RegistrationStep,
    };
  },
})
export default class RegisterView extends Vue {
  private registrationStep: RegistrationStep = RegistrationStep.Email;

  private async mounted() {
    // NOTE it is very tricky to detect logged-in and registered here
    // we would need to request /me and handle errors accordingly

    const step = this.$route.query.step
    if (step === "confirm") {
      this.registrationStep = RegistrationStep.Confirm
    }
    if (step === "mfa") {
      this.registrationStep = RegistrationStep.MFA
    }
    if (step === "details") {
      this.registrationStep = RegistrationStep.Details
    }
  }

  private goToNext(step) {
    this.registrationStep = step
  }
}
</script>
