<template>
  <v-toolbar
    app
    color="white"
    height="55px"
    scroll-off-screen
    class="google-font"
  >
    <v-toolbar-side-icon
      @click.stop="switchShowDrawer"
      class="show-drawer mr-3"
      v-if="isLoggedIn"
    ></v-toolbar-side-icon>
    <div class="nav-logo-wrapper py-3">
      <img
        :src="require('@bugseq-site/shared/src/assets/img/nav-logo.png')"
        class="nav-logo"
      />
    </div>
  </v-toolbar>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { readDashboardShowDrawer } from "@bugseq-site/app/src/store/modules/drawer/getters";
import { commitSetDashboardShowDrawer } from "@bugseq-site/app/src/store/modules/drawer/mutations";

const ComponentProps = Vue.extend({
  props: {
    isLoggedIn: Boolean,
  },
});

@Component
export default class Toolbar extends ComponentProps {
  public switchShowDrawer() {
    commitSetDashboardShowDrawer(
      this.$store,
      !readDashboardShowDrawer(this.$store),
    );
  }
}
</script>

<style>
.show-drawer {
  color: #37474f;
}

.nav-logo-wrapper {
  height: 100%;
  cursor: pointer;
}

.nav-logo {
  height: 100%;
}
</style>
