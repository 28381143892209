<template>
  <div class="pa-4">
    <div class="pb-3 headline">
      Data Explorer
    </div>
    <v-alert
      :value="true"
      outline
      type="warning"
      class="white mb-3"
    >
      Only data processed after March 23, 2024 is included.
    </v-alert>
    <div class="pb-3">
      <div @click="toggleShowCustomize()" class="customize-button">
        <v-layout align-center>
          <v-icon v-html="showCustomize ? 'expand_more' : 'chevron_right'"></v-icon>
          <div>Customize</div>
        </v-layout>
      </div>
      <div v-if="showCustomize" class="ml-5 mb-3">
        <div class="mt-3">Columns</div>
        <v-layout row wrap class="pb-3">
          <v-flex
            v-for="header in allHeaders"
            xs6
            :key="header.value"
          >
            <v-checkbox
              v-model="columns"
              @change="onColumnsChange"
              :label="header.text"
              :value="header.value"
              hide-details
            ></v-checkbox>
          </v-flex>
        </v-layout>
        <div class="mt-3">Data</div>
        <v-checkbox
          v-model="showRemovedGenomes"
          label="Show removed genomes"
          hide-details
        ></v-checkbox>
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :items="filteredSummaries"
      item-key="id"
      :pagination.sync="pagination"
      :rows-per-page-items="[5, 10, 25]"
      class="elevation-1"
      :loading="loading"
    >
      <template slot="no-data">
        <v-layout justify-center>
          <div v-if="loading">Loading...</div>
          <div v-else>No data</div>
        </v-layout>
      </template>
      <template slot="headers" slot-scope="props">
        <tr>
          <th
            v-for="header in props.headers"
            :key="header.text"
            :class="['column', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '', header.sortable ? 'sortable' : '']"
            @click="changeSort(header.value)"
          >
            <v-icon small v-if="header.sortable">arrow_upward</v-icon>
            {{ header.text }}
          </th>
        </tr>
        <tr class="grey lighten-3">
          <th
            v-for="header in props.headers"
            :key="header.text"
            class="table-header-filter"
          >
            <v-layout row wrap fill-height>
              <v-flex xs12 v-for="filter in getFilters(header.value)" :key="filter.key">
                <v-combobox
                  v-model="filter.model"
                  :label="filter.label"
                  :items="getFilterValues(filter.key)"
                  hide-selected
                  multiple
                  chips
                  clearable
                  dense
                  small-chips
                >
                  <template v-slot:selection="{ item, parent, selected }">
                    <v-chip close @input="removeFromFilter(filter, item)">{{
                      item
                    }}</v-chip>
                  </template>
                </v-combobox>
              </v-flex>
            </v-layout>
          </th>
        </tr>
      </template>
      <template slot="items" slot-scope="props">
        <tr :class="props.index % 2 === 0 ? 'grey lighten-4 noborder' : 'noborder'">
          <td class="data-explorer-row" v-if="isColVisible('job_run_name')">
            <div>
              <a
                :href="`/app/main/results/${props.item.job_run_id}`"
                target="_blank"
                class="job-run-link"
              >
                {{ props.item.job_run_name || props.item.job_run_id }}
              </a>
            </div>
            <div>
              Lab: {{ props.item.organization_name || "None" }}
            </div>
          </td>
          <td class="data-explorer-row" v-if="isColVisible('date')">
            {{ props.item.job_run_end_time.toLocaleDateString() }}
          </td>
          <td class="data-explorer-row" v-if="isColVisible('sample_name')">
            {{ props.item.sample_name }}
          </td>
          <td class="data-explorer-row" v-if="isColVisible('genome_name')">
            <PopOut
              v-if="props.item.ignore_from_outbreak_analysis"
              icon="visibility_off"
              icon-color="orange"
            >
              This genome is removed from Insights and future outbreak analyses
            </PopOut>
            {{ props.item.genome_name }}
          </td>
          <td class="data-explorer-row" v-if="isColVisible('refmlst_address')">{{ props.item.refmlst_address }}</td>
          <td class="data-explorer-row" v-if="isColVisible('sequence_type')">{{ props.item.sequence_type }}</td>
          <td class="data-explorer-row" v-if="isColVisible('plasmid_amr_determinants')">
            <v-layout
              row
              v-for="record in props.item.plasmid_amr_determinants"
              class="my-1 plasmid-determinant-row"
              :key="record.hostOrPlasmid"
            >
              <v-flex xs3 class="plasmid-determinant-plasmid">
                <span
                  :class="{'font-weight-bold': filters.plasmids.model.includes(record.hostOrPlasmid) }"
                >{{ record.hostOrPlasmid }}:</span>
              </v-flex>
              <v-flex xs9>
                <span
                  v-for="marker in [...record.markers].sort()"
                  :class="{'font-weight-bold': filters.amr_genes.model.includes(marker) }"
                  :key="marker"
                >
                  {{ marker }};
                </span>
                <span v-if="record.markers.size === 0">
                  None
                </span>
              </v-flex>
            </v-layout>
          </td>
          <td class="data-explorer-row" v-if="isColVisible('options')">
            <v-btn
              class="text-capitalize"
              @click="props.expanded = !props.expanded"
            >{{ props.expanded ? 'Hide' : 'Options' }}</v-btn>
          </td>
        </tr>
      </template>
      <template v-slot:expand="props">
        <v-card
          flat
          :class="props.index % 2 === 0 ? 'grey lighten-4 noborder px-2' : 'noborder px-2'"
        >
          <v-card-text>
            <div class="font-weight-bold my-2">Options for {{ props.item.sample_name }}: {{ props.item.genome_name }}</div>
            <v-layout justify-center>
              <PopOut
                v-if="!hasPermissionToIgnore(props.item)"
                icon="warning"
                icon-color="orange"
                class="mr-2"
              >
                Users can only ignore samples that they submitted or in labs that they manage.
              </PopOut>
              <v-checkbox
                :input-value="props.item.ignore_from_outbreak_analysis"
                :disabled="!hasPermissionToIgnore(props.item) || ignoreLoading"
                hide-details
                @change="(val) => ignoreFromOutbreakAnalysis(props.item.job_run_id, props.item.sample_name, props.item.genome_taxon_id, val)"
              >
                <template v-slot:label>
                  <span class="ignore-outbreak-analysis-label mr-1">Remove genome from Insights and future outbreak analyses</span>
                  <PopOut
                    icon="info"
                    v-if="hasPermissionToIgnore(props.item)"
                  >
                    This will remove the {{ props.item.sample_name }}: {{ props.item.genome_name }} genome from future analyses. It can be undone by toggling "Show removed genomes" in the "Customize" section and untoggling this option. Note if the sample contains multiple genomes, they each must be removed individually.
                  </PopOut>
                </template>
              </v-checkbox>
            </v-layout>
          </v-card-text>
        </v-card>
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import PopOut from "@bugseq-site/shared/src/components/PopOut.vue";
import { dispatchGetAnalysisDataSummary, dispatchGetLabMembership, dispatchUpdateGenomeSummaryIgnore } from "@bugseq-site/app/src/store/modules/api/actions";
import { readAnalysisDataSummary, readLabs, readUserProfile } from "@bugseq-site/app/src/store/modules/api/getters";

@Component({
  components: { PopOut },
})
export default class DataExplorer extends Vue {
  private loading = false
  private ignoreLoading = false

  private allHeaders = [
    { text: "Analysis", align: "left", sortable: true, value: "job_run_name", width: '20%' },
    { text: "Date", align: "left", sortable: true, value: "date", width: '10%' },
    { text: "Sample", align: "left", sortable: true, value: "sample_name", width: '20%' },
    { text: "Genome", align: "left", sortable: true, value: "genome_name", width: '20%' },
    { text: "Outbreak Address", align: "left", sortable: false, value: "refmlst_address" },
    {
      text: "Sequence Type",
      align: "left",
      sortable: false,
      value: "sequence_type",
      width: '10%',
    },
    { text: "Plasmids and AMR", align: "left", sortable: false, value: "plasmid_amr_determinants" },
    { text: "Options", align: "left", sortable: false, value: "options" },
  ];
  private headers = this.allHeaders.filter(
    h => !["date", "sequence_type"].includes(h.value),
  );
  private columns = this.allHeaders.filter(
    h => !["date", "sequence_type"].includes(h.value),
  ).map(h => h.value);

  private pagination = {
    rowsPerPage: 10,
    sortBy: null,
    descending: true,
  };

  private filters = {
    job_run_name: { key: "job_run_name", label: "Analysis", model: [] },
    organization: { key: "organization", label: "Lab", model: [] },
    sample_name: { key: "sample_name", label: "Sample", model: [] },
    genome_name: { key: "genome_name", label: "Genome", model: [] },
    plasmids: { key: "plasmids", label: "Plasmid", model: [] },
    amr_genes: { key: "amr_genes", label: "AMR Marker", model: [] },
  }

  private showCustomize = false;
  private showRemovedGenomes = false;

  public async mounted() {
    this.loading = true
    await Promise.all([
      dispatchGetLabMembership(this.$store),
      dispatchGetAnalysisDataSummary(this.$store),
    ])
    this.loading = false
  }

  private toggleShowCustomize() {
    this.showCustomize = !this.showCustomize
  }

  private onColumnsChange(updatedColumns) {
    this.headers = this.allHeaders.filter(h => updatedColumns.includes(h.value));
  }

  private isColVisible(colName) {
    return this.columns.includes(colName)
  }

  get analysisDataSummary() {
    if (this.loading) {
      return []
    }

    const analysisData = readAnalysisDataSummary(this.$store);
    const labs = readLabs(this.$store);

    // fold-in sample/genome data
    const summary = analysisData.flatMap((ad) =>
      ad.genome_summaries.map((gs) => ({
        id: `${ad.job_run_id}-${gs.sample_name}-${gs.genome_name}`,
        job_run_owner_id: ad.owner_id,
        job_run_name: ad.job_run_name,
        job_run_id: ad.job_run_id,
        organization_name: ad.organization_id ? (labs.find(l => l.id === ad.organization_id)?.name || "Unknown") : null,
        organization_id: ad.organization_id,
        job_run_end_time: ad.job_run_end_time,
        sample_name: gs.sample_name,
        genome_name: gs.genome_name,
        genome_taxon_id: gs.genome_taxon_id,
        sequence_type: (
          ad.mlst_summaries.find(
            (ms) =>
              ms.sample_name === gs.sample_name &&
              ms.genome_name === gs.genome_name,
          ) || {}
        ).sequence_type,
        plasmid_amr_determinants: (
          ad.plasmid_summaries.filter(
            (ps) =>
              ps.sample_name === gs.sample_name &&
              ps.genome_name === gs.genome_name,
          )
          .flatMap((ps) => ps.plasmids)
          .concat(["Chromosome"]) // this is for determinants that are not attached to a plasmid
          .map(hostOrPlasmid => {
            // we need to combine:
            // ad.plasmid_amr_determinant_summaries - for plasmids
            // ad.amr_summaries - for host, exclude all plasmids with AMR genes associated
            if (hostOrPlasmid === "Chromosome") {
              const plasmidResistanceMarkers = new Set(
                ad.plasmid_amr_determinant_summaries.filter(
                  (pads) => pads.sample_name === gs.sample_name
                )
                .flatMap(pads => pads.detected_resistance_markers)
              )
              const hostResistanceMarkers = new Set(
                ad.amr_summaries.filter(
                  (as) =>
                    as.sample_name === gs.sample_name &&
                    as.genome_name === gs.genome_name
                )
                .flatMap(as => as.genotypic_determinant)
              )
              const filtered = new Set(
                [...hostResistanceMarkers].filter(hr => !plasmidResistanceMarkers.has(hr))
              )
              return {
                hostOrPlasmid,
                markers: filtered,
              }
            } else {
              return {
                hostOrPlasmid,
                markers: new Set(
                  ad.plasmid_amr_determinant_summaries.filter(
                    (pads) =>
                      pads.sample_name === gs.sample_name &&
                      pads.plasmid === hostOrPlasmid
                  )
                  .flatMap(pads => pads.detected_resistance_markers)
                )
              }
            }
          })
          .filter(r => !(r.hostOrPlasmid === "Chromosome" && r.markers.size === 0)) // filter out chromosome if there are no markers
          .sort(
            (r1, r2) =>
              r1.hostOrPlasmid === "Chromosome"
                ? -1
                : r2.hostOrPlasmid === "Chromosome"
                ? 1
                : r1.hostOrPlasmid.localeCompare(r2.hostOrPlasmid)
          )
        ),
        refmlst_address: (
          ad.refmlst_summaries.find(
            (s) =>
              s.sample_name === gs.sample_name &&
              s.genome_name === gs.genome_name,
          ) || {}
        ).refmlst_address,
        ignore_from_outbreak_analysis: (
          ad.genome_ignore_summaries.some(
            (s) =>
              s.sample_name === gs.sample_name &&
              s.genome_taxon_id === gs.genome_taxon_id,
          )
        ),
      })),
    ).sort((ad1, ad2) => ad2.job_run_end_time.getTime() - ad1.job_run_end_time.getTime());

    return summary;
  }

  get filteredSummaries() {
    let analyses = this.analysisDataSummary;
    if (this.filters.job_run_name.model.length > 0) {
      analyses = analyses.filter((analysis) =>
        this.filters.job_run_name.model.some(
          (af) =>
            (analysis.job_run_name && analysis.job_run_name.startsWith(af)) ||
            analysis.job_run_id.startsWith(af),
        ),
      );
    }
    if (this.filters.sample_name.model.length > 0) {
      analyses = analyses.filter((a) =>
        this.filters.sample_name.model.some((sf) => a.sample_name.startsWith(sf)),
      );
    }
    if (this.filters.genome_name.model.length > 0) {
      analyses = analyses.filter((a) =>
        this.filters.genome_name.model.some((sf) => a.genome_name.startsWith(sf)),
      );
    }
    if (this.filters.amr_genes.model.length > 0) {
      analyses = analyses.filter((a) =>
        this.filters.amr_genes.model.some((sf) => a.plasmid_amr_determinants.some(record => record.markers.has(sf))),
      );
    }
    if (this.filters.plasmids.model.length > 0) {
      analyses = analyses.filter((a) =>
        this.filters.plasmids.model.some((sf) => a.plasmid_amr_determinants.some(record => record.hostOrPlasmid === sf)),
      );
    }
    if (this.filters.organization.model.length > 0) {
      analyses = analyses.filter((a) =>
        this.filters.organization.model.some((o) => o === (a.organization_name ?? "None")),
      );
    }
    if (!this.showRemovedGenomes) {
      analyses = analyses.filter((a) =>
        !a.ignore_from_outbreak_analysis
      );
    }

    return analyses;
  }

  get analyses() {
    return this.filteredSummaries.map((a) => a.job_run_name || a.job_run_id);
  }

  get samples() {
    return [
      ...new Set(this.filteredSummaries.map((ad) => ad.sample_name)),
    ].sort();
  }

  get genomes() {
    return [
      ...new Set(this.filteredSummaries.map((ad) => ad.genome_name)),
    ].sort();
  }

  get amrGenes() {
    return [
      ...new Set(
        this.filteredSummaries.flatMap((ad) => ad.plasmid_amr_determinants.flatMap(record => [...record.markers]))
      ),
    ].sort();
  }

  get plasmids() {
    return [
      ...new Set(
        this.filteredSummaries.flatMap((ad) => ad.plasmid_amr_determinants.map(record => record.hostOrPlasmid)).filter(p => p !== "Chromosome")
      ),
    ].sort();
  }

  get organizations() {
    return [
      ...new Set(this.filteredSummaries.map((ad) => ad.organization_name ?? "None")),
    ].sort();
  }

  get user() {
    return readUserProfile(this.$store);
  }

  private getFilters(headerValue) {
    // unfilterable cols
    if (["sequence_type", "date", "refmlst_address", "options"].includes(headerValue)) {
      return []
    }

    if (headerValue === "plasmid_amr_determinants") {
      return [this.filters.plasmids, this.filters.amr_genes]
    }

    if (headerValue === "job_run_name") {
      return [this.filters.job_run_name, this.filters.organization]
    }

    if (this.filters.hasOwnProperty(headerValue)) {
      return [this.filters[headerValue]]
    }

    throw new Error(`Unrecognized filter: ${headerValue}`)
  }

  private removeFromFilter(filter, item) {
    filter.model = filter.model.filter((i) => i !== item)
  }

  private getFilterValues(filterKey) {
    switch (filterKey) {
      case "job_run_name":
        return this.analyses
      case "organization":
        return this.organizations
      case "sample_name":
        return this.samples
      case "genome_name":
        return this.genomes
      case "plasmids":
        return this.plasmids
      case "amr_genes":
        return this.amrGenes
      default:
        throw new Error(`Unrecognized filter key: ${filterKey}`)
    }
  }

  private changeSort(column) {
    if (this.pagination.sortBy === column) {
      this.pagination.descending = !this.pagination.descending
    } else {
      this.pagination.sortBy = column
      this.pagination.descending = false
    }
  }

  private hasPermissionToIgnore(summary) {
    if (!this.user) {
      return false
    }

    if (summary.job_run_owner_id === this.user.id) {
      return true
    }

    if (readLabs(this.$store).find(l => l.id === summary.organization_id)?.manager_id === this.user.id) {
      return true
    }

    return false
  }

  private async ignoreFromOutbreakAnalysis(jobId: string, sampleName: string, genomeTaxonId: number, ignore: boolean) {
    this.ignoreLoading = true
    try {
      await dispatchUpdateGenomeSummaryIgnore(this.$store, {
        jobId,
        payload: {
          sample_name: sampleName,
          genome_taxon_id: genomeTaxonId,
          ignore: ignore,
        },
      })
    } finally {
      this.ignoreLoading = false
    }
  }
}
</script>

<style scoped>
.customize-button {
  cursor: pointer;
}

table.v-table tbody tr.noborder {
  border-top: none;
}

.table-header-filter {
  height: 100%;
}

.plasmid-determinant-plasmid {
  min-width: 90px;
}

.plasmid-determinant-row {
  min-width: 300px;
}

.job-run-link {
  word-break: break-all;
}

.ignore-outbreak-analysis-label {
  font-size: 13px;
}

/* not sure why scoped styles don't work properly here... */
:global(table.v-table tbody td.data-explorer-row) {
  height: 36px;
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
