import { ActionContext } from 'vuex'
import { getStoreAccessors } from 'typesafe-vuex'
import { RootState } from '../../state'
import { commitSetDashboardMiniDrawer, commitSetDashboardShowDrawer } from './mutations'
import { DrawerState } from './state'

type DrawerContext = ActionContext<DrawerState, RootState>

export const actions = {
  async setDashboardMiniDrawer (context: DrawerContext, show: boolean) {
    commitSetDashboardMiniDrawer(context, show)
  },
  async setDashboardShowDrawer (context: DrawerContext, show: boolean) {
    commitSetDashboardShowDrawer(context, show)
  }
}

const { dispatch } = getStoreAccessors<DrawerState, RootState>('drawer')

export const dispatchSetDashboardMiniDrawer = dispatch(actions.setDashboardMiniDrawer)
export const dispatchSetDashboardShowDrawer = dispatch(actions.setDashboardShowDrawer)
