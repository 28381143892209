import { getStoreAccessors } from 'typesafe-vuex'
import { components } from '@bugseq-site/app/src/lib/api/api'
import { RootState } from '../../state'
import { ApiState } from './state'

export const mutations = {
  setUserProfile (state: ApiState, payload: components['schemas']['UserResponse']) {
    state.userProfile = payload
  },
  setJobResults (
    state: ApiState,
    { jobId, results }: { jobId: string, results: components['schemas']['JobRunResultsResponse'] }
  ) {
    state.jobResults = { [jobId]: results }
  },
  setLabs (state: ApiState, payload: Array<components['schemas']['ListMembershipsResponse']>) {
    state.labs = payload.sort((a, b) => a.name.localeCompare(b.name))
  },
  addLabInvites (state: ApiState, payload: Array<components['schemas']['InviteResponse']>) {
    const newIds = new Set(payload.map(i => i.id))
    state.labInvites = state.labInvites.filter(i => !newIds.has(i.id)).concat(payload)
  },
  setLabMembership (state: ApiState, payload: {
    labId: string
    preferences: components['schemas']['OrganizationMembershipPreferences']
  }) {
    const updatedLabs = state.labs.filter(lab => lab.id !== payload.labId)
    const labToUpdate = state.labs.find(lab => lab.id === payload.labId)
    if (labToUpdate === undefined || labToUpdate === null) {
      throw new Error('lab to update not found')
    }

    const updatedLab = { ...labToUpdate, member_preferences: payload.preferences }
    updatedLabs.push(updatedLab)
    state.labs = updatedLabs.sort((a, b) => a.name.localeCompare(b.name))
  },
  setAnalysisDataSummary (state: ApiState, payload: Array<components['schemas']['AnalysisDataSummaryResponse']>) {
    state.analysisDataSummary = payload
  },
  setAnalysisDataGenomeIgnoreSummary (
    state: ApiState,
    payload: { jobId: string, payload: components['schemas']['GenomeSummaryIgnoreUpdateRequest'] }
  ) {
    state.analysisDataSummary = state.analysisDataSummary.map(ads => {
      if (payload.payload.ignore) {
        const ignoreSummaries = ads.genome_ignore_summaries
        if (ads.job_run_id === payload.jobId) {
          ignoreSummaries.push({ sample_name: payload.payload.sample_name, genome_taxon_id: payload.payload.genome_taxon_id })
        }
        return {
          ...ads,
          genome_ignore_summaries: ignoreSummaries
        }
      } else {
        return {
          ...ads,
          genome_ignore_summaries: ads.genome_ignore_summaries.filter(
            gis => !(
              ads.job_run_id === payload.jobId &&
                gis.sample_name === payload.payload.sample_name &&
                gis.genome_taxon_id === payload.payload.genome_taxon_id
            )
          )
        }
      }
    })
  }
}

const { commit } = getStoreAccessors<ApiState, RootState>('api')

export const commitSetUserProfile = commit(mutations.setUserProfile)
export const commitSetJobResults = commit(mutations.setJobResults)
export const commitSetLabs = commit(mutations.setLabs)
export const commitAddLabInvites = commit(mutations.addLabInvites)
export const commitLabMembershipUpdate = commit(mutations.setLabMembership)
export const commitSetAnalysisDataSummary = commit(
  mutations.setAnalysisDataSummary
)
export const commitSetAnalysisDataGenomeIgnoreSummary = commit(
  mutations.setAnalysisDataGenomeIgnoreSummary
)
