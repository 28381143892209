<template>
  <v-app>
    <Submitted :source="source" />
  </v-app>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Submitted from "@bugseq-site/app/src/components/app/Submitted.vue";

const ComponentProps = Vue.extend({
  props: {
    source: {
      type: String,
      required: false,
    },
  },
});

@Component({
  metaInfo() {
    return {
      title: "Success!",
    };
  },
  components: { Submitted },
})
export default class SubmittedView extends ComponentProps {}
</script>
