<template>
  <div class="pa-4">
    <div class="pb-3 headline">
      Analyses
    </div>
    <div class="pb-3">
      <div @click="toggleShowFilter()" class="filter-button">
        <v-layout align-center>
          <v-icon v-html="showFilter ? 'expand_more' : 'chevron_right'"></v-icon>
          <div>Filter</div>
        </v-layout>
      </div>
      <div v-if="showFilter" class="ml-5 mb-3">
        <div class="font-weight-bold mt-2">Analysis Name</div>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search by Name"
          single-line
          hide-details
          class="filter-max-width mb-4 pt-0"
        ></v-text-field>
        <div class="font-weight-bold">Lab</div>
        <v-select
          v-model="filterLabs"
          item-text="name"
          item-value="id"
          :items="labs"
          chips
          label="Labs"
          multiple
          hide-details
          class="filter-max-width mb-4"
        >
          <template v-slot:no-data>
            <div class="pa-3">
              You are not a member of any labs.
            </div>
          </template>
        </v-select>
        <div class="font-weight-bold">Submitter</div>
        <v-checkbox
          v-model="showOnlyMe"
          label="Only show analyses submitted by me"
          hide-details
          class="pt-0"
        ></v-checkbox>
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :items="jobs"
      :total-items="totalJobs"
      :pagination.sync="pagination"
      :rows-per-page-items="[10, 25, 50]"
      :loading="loading"
      class="elevation-1"
      must-sort
      expand
    >
      <template v-slot:no-data>
        <span v-if="!loading">No results found! <a href="/app/main/dashboard">Submit a new analysis</a>.</span>
      </template>
      <template v-slot:pageText="props">
        {{ props.pageStart }} - {{ props.pageStop }}
      </template>
      <template slot="items" slot-scope="props">
        <tr>
          <td class="py-3">
            <div class="mr-3">
              <div>
                {{ props.item.user_provided_name || props.item.id }}
              </div>
              <div
                v-if="props.item.user_provided_name"
                class="mt-1 secondary--text text--lighten-2"
              >
                {{ props.item.id }}
              </div>
            </div>
          </td>
          <td>
            <JobResultsChip :jobStatus="props.item.job_status" />
          </td>
          <td class="text-no-wrap">{{ props.item.created | formatTime }}</td>
          <td class="py-3">
            <div v-if="getLabName(props.item)">
              <a :href="`/app/main/labs/${getLabId(props.item)}`" target="_blank">{{ getLabName(props.item) }}</a>
            </div>
            <div
              v-if="getOwnerName(props.item)"
              class="mt-1 secondary--text text--lighten-2"
            >
              {{ getOwnerName(props.item) }}
            </div>
          </td>
          <td>
            <v-btn
              color="primary"
              class="text-capitalize"
              :disabled="props.item.job_status !== 'success' && props.item.job_status !== 'marked_in_error'"
              :href="props.item.results_url"
              target="_blank"
              >Results</v-btn
            >
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { Store } from "vuex";
import JobResultsChip from "@bugseq-site/shared/src/components/JobResultsChip.vue";
import { components, paths } from "@bugseq-site/app/src/lib/api/api";
import {
  dispatchGetUserRuns,
  dispatchGetLabMembership,
} from "@bugseq-site/app/src/store/modules/api/actions";
import { readLabs, readUserProfile } from "@bugseq-site/app/src/store/modules/api/getters";
import { formatTime } from "@bugseq-site/shared/src/lib/utils";

@Component({
  filters: {
    formatTime,
  },
  components: {
    JobResultsChip,
  },
})
export default class JobTable extends Vue {
  private pagination = {
    sortBy: "created",
    descending: true,
    page: 1,
    rowsPerPage: 10,
  };
  private loading: boolean = true;
  private search = "";
  private jobs: components["schemas"]["JobRunResponse"][] = [];
  private totalJobs: number = 10000;

  private headers = [
    {
      text: "Name",
      sortable: false,
      value: "id",
      align: "left",
    },
    {
      text: "",
      sortable: false,
      value: "status",
      align: "left",
      width: "1%",
    },
    {
      text: "Submitted",
      sortable: false,
      value: "created",
      align: "left",
      width: "1%",
    },
    {
      text: "Lab/Submitter",
      sortable: false,
      value: "lab",
      align: "left",
      width: "15%",
    }
  ];

  private showFilter = false;
  private filterLabs = [];
  private showOnlyMe = false;

  private mounted() {
    dispatchGetLabMembership(this.$store);
  }

  private toggleShowFilter() {
    this.showFilter = !this.showFilter
  }

  get user() {
    return readUserProfile(this.$store);
  }

  get labs() {
    return readLabs(this.$store).sort((a, b) => a.name.localeCompare(b.name));
  }

  public getOwnerName(item: components["schemas"]["JobRunResponse"]) {
    if (!this.user) {
      return ""
    }

    if (item.owner_id === this.user.id) {
      return this.user.first_name + " " + this.user.last_name;
    }

    const owner = ((this.labs || []) as components["schemas"]["ListMembershipsResponse"][])
      .flatMap((l) => l.members)
      .filter((m) => m.id === item.owner_id);
    if (owner.length > 0) {
      return owner[0].first_name + " " + owner[0].last_name;
    }

    return "";
  }

  public getLab(item: components["schemas"]["JobRunResponse"]): components["schemas"]["ListMembershipsResponse"] | null {
    if (item.org_id === null) {
      return null
    }

    const lab = ((this.labs || []) as components["schemas"]["ListMembershipsResponse"][])
      .find((l) => l.id === item.org_id)

    if (!lab) {
      return null
    }

    return lab
  }

  public getLabName(item: components["schemas"]["JobRunResponse"]): string | null {
    const lab = this.getLab(item)
    if (!lab) {
      return null
    }
    return lab.name
  }

  public getLabId(item: components["schemas"]["JobRunResponse"]): string | null {
    const lab = this.getLab(item)
    if (!lab) {
      return null
    }
    return lab.id
  }

  @Watch("search", { deep: true })
  public async searchChanged() {
    // updating does not trigger paginationChanged.
    // reset page as it doesn't make sense to continue with old pagination.
    this.pagination.page = 1;
    return this.paginationChanged(this.pagination);
  }

  @Watch("filterLabs")
  public async filterLabsChanged() {
    // updating does not trigger paginationChanged.
    // reset page as it doesn't make sense to continue with old pagination.
    this.pagination.page = 1;
    this.paginationChanged(this.pagination);
  }

  @Watch("showOnlyMe")
  public async showOnlyMeChanged() {
    // updating does not trigger paginationChanged.
    // reset page as it doesn't make sense to continue with old pagination.
    this.pagination.page = 1;
    this.paginationChanged(this.pagination);
  }

  @Watch("pagination", { deep: true })
  public async paginationChanged(pagination) {
    const { descending, page, rowsPerPage } = pagination;

    const opts: paths['/v1/jobs/']['get']['parameters']['query'] = {
      limit: rowsPerPage,
      skip: (page - 1) * rowsPerPage,
    };

    if (this.showOnlyMe) {
      opts.owner_ids = [this.user!.id];
    }

    if (this.filterLabs.length > 0) {
      opts.lab_ids = this.filterLabs;
    }

    // can't limit search length to e.g. >3.
    // if we early-returned here, user could get stuck with 2 chars where pagination will stop making requests
    if (this.search.length > 0) {
      opts.user_provided_name = this.search;
    }

    this.loading = true;
    try {
      const resp = await dispatchGetUserRuns(this.$store, opts);
      if (resp) {
        this.jobs = resp.job_runs;
        if (!resp.has_more) {
          this.totalJobs = (pagination.page-1) * pagination.rowsPerPage + resp.job_runs.length
        } else {
          this.totalJobs = 10000
        }
      }
    } finally {
      this.loading = false;
    }
  }
}
</script>

<style scoped>
.filter-button {
  cursor: pointer;
}

.filter-max-width {
  max-width: 400px;
}
</style>
