import { Amplify } from 'aws-amplify'
import { fetchAuthSession } from 'aws-amplify/auth'
import {
  getCognitoPoolData,
  getCognitoRedirectURL,
  getCognitoRedirectSignOutURL,
  getCognitoURL
} from '@bugseq-site/shared/src/env/auth'

export class CognitoSessionError extends Error {}

export async function getAuthHeaders (): Promise<Headers> {
  const headers = new Headers()

  try {
    const session = await fetchAuthSession()
    const token = session?.tokens?.accessToken.toString() as string | null
    if (token == null) {
      throw new CognitoSessionError('token not found')
    }
    headers.set('Authorization', `Bearer ${token}`)
  } catch (e) {
    throw new CognitoSessionError('getting cognito access token', { cause: e })
  }

  return headers
}

export function registerAuth (): void {
  const cognitoPoolData = getCognitoPoolData()
  // https://docs.amplify.aws/lib/client-configuration/configuring-amplify-categories/q/platform/js/#top-level-configuration
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: cognitoPoolData.userPoolId,
        userPoolClientId: cognitoPoolData.clientId,

        loginWith: {
          oauth: {
            domain: getCognitoURL(),
            scopes: ['email', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: [getCognitoRedirectURL()],
            redirectSignOut: [getCognitoRedirectSignOutURL()],
            responseType: 'code'
          },
          email: true
        }
      }
    }
  })
}
