<template>
  <v-form class="px-3">
    <v-alert
      :value="!isLabManager()"
      class="font-weight-bold"
      type="warning"
    >
      Only the Lab manager can invite members to the lab.
    </v-alert>
    <div class="subheading mt-3">
      <b>Email</b>
    </div>
    <v-text-field
      label="felix@mylab.com"
      v-model="inviteeEmail"
      name="Email"
      v-validate="'required|email'"
      single-line
      :disabled="inviteSent"
      :error-messages="errors.first('Email')"
    ></v-text-field>
    <div class="subheading mt-3">
      <b>Role</b>
    </div>
    <div class="my-2 font-italic">Read more about lab roles <a href="https://docs.bugseq.com/labs/#roles" target="_blank">here</a>.</div>
    <v-radio-group v-model="memberRole">
      <v-radio
        v-for="[memberRoleValue, memberRoleKey] in memberRoles"
        :key="memberRoleKey"
        :label="memberRoleKey"
        :value="memberRoleValue"
        :disabled="inviteSent"
      ></v-radio>
    </v-radio-group>
    <v-btn
      color="primary"
      class="text-capitalize ml-0"
      v-on:click="invite()"
      v-if="!inviteSent"
      :disabled="errors.items.length > 0 || !isLabManager()"
      >Invite</v-btn
    >
    <div v-if="inviteSent">
      An email has been sent to {{ inviteeEmail }} with instructions to join
      {{ lab.name }}. If you have any troubles, please reach out to
      <a href="mailto:contact@bugseq.com">contact@bugseq.com</a>.
    </div>
    <v-btn
      color="primary"
      class="text-capitalize ml-0 mt-4"
      v-on:click="resetInvite()"
      v-if="inviteSent"
      >Reset/Invite Another</v-btn
    >
  </v-form>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { components } from '@bugseq-site/app/src/lib/api/api'
import { dispatchInviteToLab } from "@bugseq-site/app/src/store/modules/api/actions";
import { readUserProfile } from "@bugseq-site/app/src/store/modules/api/getters";

enum MemberRole {
  SUBMITTER = "SUBMITTER",
  REVIEWER = "REVIEWER",
}

const ComponentProps = Vue.extend({
  props: {
    lab: Object,
  },
});

@Component({
  data() {
    return {
      MemberRole,
    };
  },
})
export default class LabInviteForm extends ComponentProps {
  private inviteeEmail: string = "";
  private inviteSent: boolean = false;

  private memberRoles: Map<components['schemas']['OrgMemberRole'], string> = new Map([
    [MemberRole.SUBMITTER, "Submitter"],
    [MemberRole.REVIEWER, "Reviewer"],
  ]);
  private memberRole: components['schemas']['OrgMemberRole'] = MemberRole.SUBMITTER;

  private async invite() {
    if (!(await this.$validator.validateAll())) {
      return
    }

    await dispatchInviteToLab(this.$store, {
      organizationId: this.lab.id,
      inviteeEmail: this.inviteeEmail,
      memberRole: this.memberRole,
    });
    this.inviteSent = true;
  }

  private resetInvite() {
    this.inviteSent = false;
    this.inviteeEmail = "";
  }

  private isLabManager() {
    const user = readUserProfile(this.$store)
    if (!user || !this.lab) {
      return false
    }

    return this.lab.manager_id === user.id
  }
}
</script>
