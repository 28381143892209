<template>
  <DataExplorer />
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import DataExplorer from "@bugseq-site/app/src/components/app/DataExplorer.vue";

@Component({
  components: {
    DataExplorer,
  },
  metaInfo: {
    title: "Insights",
  },
})
export default class DataExplorerPage extends Vue {}
</script>
