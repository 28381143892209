<template>
  <LabInviteAccept :invite="invite" />
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import LabInviteAccept from "@bugseq-site/app/src/components/app/LabInviteAccept.vue";

@Component({
  metaInfo: {
    title: "Join Lab",
  },
  components: { LabInviteAccept },
})
export default class LabInviteAcceptView extends Vue {
  public invite!: string;
  public data() {
    return {
      invite: this.$route.params.invite,
    };
  }
}
</script>
