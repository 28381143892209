<template>
  <Dropbox />
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Dropbox from "@bugseq-site/app/src/components/app/Dropbox.vue";

@Component({
  components: {
    Dropbox,
  },
  metaInfo: {
    title: "Dropbox",
  },
})
export default class DropboxView extends Vue {}
</script>
