import createClient from 'openapi-fetch'
import { paths } from '@bugseq-site/app/src/lib/api/api'
import { getApiUrl } from '@bugseq-site/shared/src/env'
import { getAuthHeaders } from '@bugseq-site/shared/src/lib/api/auth'
import { isRawBugSeqApiError, BugSeqApiError } from '@bugseq-site/shared/src/lib/api/errors'

const { POST } = createClient<paths>({ baseUrl: getApiUrl() })

class BasespaceSampleUpload {
  public basespaceProjectId: string
  public basespaceProjectName: string
  public basespaceSampleId: string
  public basespaceSampleName: string

  public bugseqId?: string
  public err?: string

  constructor (
    basespaceProjectId: string,
    basespaceProjectName: string,
    basespaceSampleId: string,
    basespaceSampleName: string
  ) {
    this.basespaceProjectId = basespaceProjectId
    this.basespaceProjectName = basespaceProjectName
    this.basespaceSampleId = basespaceSampleId
    this.basespaceSampleName = basespaceSampleName
  }
}

export class BasespaceSampleUploader {
  public inProgress: BasespaceSampleUpload[]
  public succeeded: BasespaceSampleUpload[]
  public failed: BasespaceSampleUpload[]

  constructor () {
    this.inProgress = []
    this.succeeded = []
    this.failed = []
  }

  public async upload (
    projectId: string,
    projectName: string,
    sampleId: string,
    sampleName: string
  ): Promise<void> {
    const upload = new BasespaceSampleUpload(
      projectId,
      projectName,
      sampleId,
      sampleName
    )

    if (
      this.inProgress
        .concat(this.succeeded)
        .some((u) => u.basespaceSampleId === sampleId)
    ) {
      upload.err = `It looks like you have already attempted to add sample ${sampleName}. Please remove the sample from the "Failed" section.`
      this.failed.push(upload)
      return
    }

    try {
      this.inProgress.push(upload)
      const { data, error } = await POST('/v1/files/basespace/sample', {
        headers: await getAuthHeaders(),
        body: {
          basespace_project_id: projectId,
          basespace_project_name: projectName,
          basespace_sample_id: sampleId,
          basespace_sample_name: sampleName
        }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw error
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-unnecessary-type-assertion
      upload.bugseqId = data!.id
      this.succeeded.push(upload)
    } catch (e) {
      // tslint:disable: no-console
      console.error(e)
      if (e instanceof BugSeqApiError) {
        upload.err = e.message
      }
      this.failed.push(upload)
    } finally {
      const index = this.inProgress.indexOf(upload)
      if (index !== -1) {
        this.inProgress.splice(index, 1)
      }
    }
  }

  public deleteSucceeded (id: string): void {
    this.succeeded = this.succeeded.filter((f) => f.basespaceSampleId !== id)
  }

  public deleteFailed (id: string): void {
    this.failed = this.failed.filter((f) => f.basespaceSampleId !== id)
  }
}
