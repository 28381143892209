<template>
  <SubmissionForm />
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import SubmissionForm from "@bugseq-site/app/src/components/app/SubmissionForm.vue";

@Component({
  components: {
    SubmissionForm,
  },
  metaInfo: {
    title: "New Analysis",
  },
})
export default class Dashboard extends Vue {}
</script>
