<template>
  <router-view />
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import { dispatchGetLabMembership } from "@bugseq-site/app/src/store/modules/api/actions";

@Component
export default class LabsPage extends Vue {
  public async mounted() {
    await dispatchGetLabMembership(this.$store);
  }
}
</script>
