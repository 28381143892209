import { Module } from 'vuex'
import Router from 'vue-router'
import { Client } from '@bugseq-site/shared/src/lib/api/client'
import { paths } from '@bugseq-site/app/src/lib/api/api'
import { ApiState } from './state'
import { actions } from './actions'
import { getters } from './getters'
import { mutations } from './mutations'

export function createApiModule (client: Client<paths>, router: Router): Module<ApiState, any> {
  const defaultState: ApiState = {
    client,
    router,

    userProfile: null,
    jobResults: {},
    labs: [],
    labInvites: [],
    analysisDataSummary: []
  }

  return {
    namespaced: true,
    state: defaultState,
    actions,
    getters,
    mutations
  }
}
