<template>
  <v-card class="elevation-12 mt-4">
    <v-toolbar dark color="primary">
      <v-toolbar-title>Register</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-card-text>
      <div class="pa-4" v-if="!isUnauthenticated">
        <div class="pt-2 headline">
          Enable 2-Factor Authentication
        </div>
        <div v-if="mfaType === null">
          <div class="mt-4">We recommend enabling a second factor of authentication to protect your account.</div>
          <v-layout class="mt-4">
            <v-flex xs6 class="pr-2">
              <v-layout column align-center fill-height class="mfa-option pa-2" @click="selectType(MFAType.SMS)">
                <v-flex class="font-weight-bold">Text-message (SMS) based</v-flex>
                <v-flex><v-icon x-large class="my-3">sms</v-icon></v-flex>
                <v-flex>Receive a text message with a code each time you log in</v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs6 class="pl-2">
              <v-layout column align-center fill-height class="mfa-option pa-2" @click="selectType(MFAType.TOTP)">
                <v-flex class="font-weight-bold">App Based</v-flex>
                <v-flex><v-icon x-large class="my-3">phonelink_lock</v-icon></v-flex>
                <v-flex>Use an app like Google Authenticator for codes</v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </div>
        <div v-else-if="mfaType === MFAType.TOTP">
          <div class="mt-3 pb-3 font-weight-bold">
            App-Based
          </div>
          App-based 2-factor authentication uses a mobile app like Google Authenticator as a second factor of authentication.
          <div v-if="totpStep === TOTPStep.WaitingForInit">
            <v-btn
              class="text-capitalize mx-0 mt-2"
              color="primary"
              @click="initTotp()"
              >Begin Setup</v-btn
            >
            <v-btn
              class="text-capitalize ml-3 mt-2"
              @click="reset"
              >Use a Different Method</v-btn
            >
          </div>
          <div v-if="totpStep === TOTPStep.TokenAssociated">
            <div class="my-3">
              Scan the following QR code using an auth app (e.g. Google Authenticator).
            </div>
            <QrcodeVue :value="totpToken" size="200" />
            <div class="mt-2">Or manually enter: <code class="totp-raw">{{ totpTokenRaw }}</code></div>
            <div class="my-3">
              Enter a code from the app and click "Verify"
            </div>
            <v-text-field label="Code" name="Code" v-model="totpCode"></v-text-field>
            <v-btn
              class="text-capitalize mx-0 mt-2"
              color="primary"
              @click="verifyTotp(totpCode)"
              >Verify</v-btn
            >
            <v-btn
              class="text-capitalize ml-3 mt-2"
              @click="reset"
              >Use a Different Method</v-btn
            >
          </div>
        </div>
        <div v-else-if="mfaType === MFAType.SMS">
          <div class="mt-3 py-3 font-weight-bold">
            SMS-Based
          </div>
          SMS-Based authentication uses your phone as a second factor via
          text-message.
          <div v-if="smsStep === SMSStep.PhoneAlreadyVerified" class="mt-2">
            Phone number {{ alreadyVerifiedPhoneNumber }} is already verified.
          </div>
          <div v-if="smsStep === SMSStep.WaitingForPhone">
            <v-text-field
              label="Phone Number"
              name="Phone Number"
              v-model="phoneNumber"
            ></v-text-field>
            <div
              class="error--text"
              v-if="phoneNumber && !phoneNumber.startsWith('1')"
            >
              Phone number should start with country code (e.g. 13332229999 for USA)
            </div>
            <v-btn
              class="text-capitalize mx-0 mt-2"
              color="primary"
              @click="initPhone()"
              >Send Code</v-btn
            >
            <v-btn
              class="text-capitalize ml-3 mt-2"
              @click="reset"
              >Use a Different Method</v-btn
            >
          </div>
          <div v-if="smsStep === SMSStep.PhoneEntered">
            <div class="my-3">
              Enter the code that was just sent to your phone
            </div>
            <v-text-field label="Code" name="Code" v-model="smsCode"></v-text-field>
            <v-btn
              class="text-capitalize mx-0 mt-2"
              color="primary"
              @click="verifyPhone(smsCode)"
              >Verify</v-btn
            >
            <v-btn
              class="text-capitalize ml-3 mt-2"
              @click="reset"
              >Use a Different Method</v-btn
            >
          </div>
        </div>
        <div v-if="mfaType === null">
          <v-layout justify-center>
            <v-btn
              class="text-capitalize mx-0 mt-5"
              outline
              color="red"
              @click="nextStep"
            >Do not add 2FA</v-btn>
          </v-layout>
        </div>
        <div v-if="errorMessage" class="red--text">{{ errorMessage }}</div>
      </div>
      <div class="pa-4" v-else>
        <div>Log in to continue registration</div>
        <v-btn
          class="text-capitalize mx-0 mt-4"
          color="primary"
          @click="goToLogin"
        >Go to Log In</v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { PropType } from "vue";
import { Component, Vue } from "vue-property-decorator";
import { fetchUserAttributes, confirmUserAttribute, setUpTOTP, updateMFAPreference, updateUserAttributes, verifyTOTPSetup } from 'aws-amplify/auth';
import QrcodeVue from "qrcode.vue";

enum MFAType {
  TOTP,
  SMS,
}

enum TOTPStep {
  WaitingForInit,
  TokenAssociated,
  TokenVerified,
}

enum SMSStep {
  PhoneAlreadyVerified,
  WaitingForPhone,
  PhoneEntered,
  PhoneVerified,
}

const ComponentProps = Vue.extend({
  props: {
    next: Function as PropType<() => void>,
  },
});

@Component({
  components: { QrcodeVue },
  data() {
    return {
      MFAType,
      SMSStep,
      TOTPStep,
    };
  },
})
export default class RegisterMFA extends ComponentProps {
  private mfaType: MFAType | null = null;

  private email: string = "";

  private totpStep: TOTPStep = TOTPStep.WaitingForInit;
  private totpToken: string = "";
  private totpTokenRaw: string = "";
  private totpCode: string = "";

  private smsStep: SMSStep = SMSStep.WaitingForPhone;
  private phoneNumber: string = "";
  private smsCode: string = "";

  private alreadyVerifiedPhoneNumber?: string;

  private isUnauthenticated: boolean = false;
  private errorMessage: string = "";

  private async mounted() {
    try {
      const userAttributes = await fetchUserAttributes();
      if (userAttributes.phone_number_verified) {
        this.alreadyVerifiedPhoneNumber = userAttributes.phone_number;
        this.smsStep = SMSStep.PhoneAlreadyVerified;
      }

      this.email = userAttributes.email!;
    } catch (error) {
      if (error instanceof Error) {
        if (error.name === "UserUnAuthenticatedException") {
          this.isUnauthenticated = true
        }
      } else {
        console.error("could not parse error", error)
        this.errorMessage = "Unknown error"
      }
    }
  }

  private goToLogin() {
    this.$router.push({ path: "/login", query: this.$route.query })
  }

  private reset() {
    this.mfaType = null;
    this.totpStep = TOTPStep.WaitingForInit;
    this.smsStep = SMSStep.WaitingForPhone;
  }

  private selectType(type: MFAType) {
    this.mfaType = type;
  }

  private nextStep() {
    this.$router.push({ query: { ...this.$route.query, step: "details" } })
    this.next()
  }

  private async initTotp() {
    const totpSetupDetails = await setUpTOTP();
    this.totpToken = totpSetupDetails.getSetupUri("BugSeq", this.email).toString();
    this.totpTokenRaw = totpSetupDetails.sharedSecret;
    this.totpStep = TOTPStep.TokenAssociated;
  }

  private async verifyTotp(code) {
    try {
      await verifyTOTPSetup({ code });
      await updateMFAPreference({ totp: "PREFERRED" });
    } catch (error) {
      if (error instanceof Error) {
        this.errorMessage = error.message
      } else {
        console.error("could not parse error", error)
        this.errorMessage = "Unknown error"
      }
    }
    this.totpStep = TOTPStep.TokenVerified;
    this.nextStep()
  }

  private async initPhone() {
    // format per https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-attributes.html
    let phoneNumber = this.phoneNumber;
    phoneNumber = phoneNumber.replace(/\D/g, "");
    phoneNumber = "+" + phoneNumber;

    await updateUserAttributes({
      userAttributes: {
        phone_number: phoneNumber,
      },
    });
    this.smsStep = SMSStep.PhoneEntered;
  }

  private async verifyPhone(challengeAnswer) {
    try {
      await confirmUserAttribute({
          userAttributeKey: 'phone_number',
          confirmationCode: challengeAnswer,
        }
      );
      await updateMFAPreference({ sms: "PREFERRED" });
    } catch (error) {
      if (error instanceof Error) {
        this.errorMessage = error.message
      } else {
        console.error("could not parse error", error)
        this.errorMessage = "Unknown error"
      }
    }
    this.smsStep = SMSStep.PhoneVerified;
    this.nextStep()
  }
}
</script>

<style scoped>
.mfa-option {
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
}

.totp-raw {
  word-break: break-all;
}
</style>
