import { Module } from 'vuex'
import { DrawerState } from './state'
import { mutations } from './mutations'
import { getters } from './getters'
import { actions } from './actions'

export const drawer: Module<DrawerState, any> = {
  namespaced: true,
  state: {
    dashboardMiniDrawer: false,
    dashboardShowDrawer: true
  },
  actions,
  mutations,
  getters
}
