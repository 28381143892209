<template>
  <v-container fluid>
    <v-layout align-center column class="ma-4">
      <v-flex v-if="!loginError">
        If you are not redirected within a few seconds, please try logging in again.
      </v-flex>
      <v-flex v-else class="red--text">
        {{ loginError }}
      </v-flex>
      <v-flex class="mt-3">
        If you are still having issues, please <a href="mailto:support@bugseq.com?subject=Trouble logging in to BugSeq&body=Hi! I'm having trouble logging into BugSeq.">contact support</a>.
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Hub } from "aws-amplify/utils";
import 'aws-amplify/auth/enable-oauth-listener';
import { getCurrentUser } from "aws-amplify/auth";
import { BugSeqApiError } from '@bugseq-site/shared/src/lib/api/errors'
import { dispatchCheckLoggedIn } from "@bugseq-site/app/src/store/modules/api/actions";

@Component
export default class Cognito extends Vue {
  private loginError: string = "";
  private redirect?: string;
  private redirectPromise: Promise<string>;
  private redirectResolve?: (addr: string) => void;

  constructor() {
    super();

    this.redirectPromise = new Promise((res) => {
      this.redirectResolve = res;
    });

    // this only happens for federated login where we wait for a redirect from cognito state
    Hub.listen("auth", async ({ payload }) => {
      switch (payload.event) {
        case "signedIn":
          // safe to resolve twice, and custom state should be figured out by now
          // https://stackoverflow.com/a/29491617
          this.redirectResolve!("/app/main/dashboard")
          break
        case "customOAuthState":
          const parsed = JSON.parse(payload.data);
          this.redirectResolve!(parsed.redirect);
          break
      }
    });
  }

  public async mounted() {
    const errorDescription = this.$route.query.error_description as string
    if (errorDescription) {
      this.loginError = errorDescription;
      return
    }

    let redirect = this.$route.query.redirect as string
    if (!redirect) {
      redirect = await this.redirectPromise;
    }

    try {
      await dispatchCheckLoggedIn(this.$store);
    } catch (error) {
      if (error instanceof BugSeqApiError) {
        // for signup links, sometimes we direct to /register with prepopulated params
        if (redirect.startsWith("/register")) {
          this.$router.push({ path: redirect })
        } else {
          // and sometimes it's where the user should be redirected after they register
          this.$router.push({ path: "/register", query: { redirect, step: "details" } });
        }
        return;
      }
      throw error
    }

    this.$router.push(redirect);
  }
}
</script>
