<template>
  <Lab />
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Lab from "@bugseq-site/app/src/components/app/Lab.vue";

@Component({
  components: {
    Lab,
  },
  metaInfo: {
    title: "Lab",
  },
})
export default class LabPage extends Vue {}
</script>
