<template>
  <div id="app">
    <v-app>
      <Toolbar :isLoggedIn="isLoggedIn()" />
      <AppNav v-if="isLoggedIn()" />
      <v-content>
        <router-view />
      </v-content>
      <NotificationsManager></NotificationsManager>
    </v-app>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import AppNav from "@bugseq-site/app/src/components/app/AppNav.vue";
import NotificationsManager from "@bugseq-site/app/src/components/NotificationsManager.vue";
import Toolbar from "@bugseq-site/app/src/components/Toolbar.vue";
import { readUserProfile } from "@bugseq-site/app/src/store/modules/api/getters"

@Component({
  metaInfo() {
    return {
      title: "BugSeq",
      titleTemplate: "BugSeq | %s"
    };
  },
  components: {
    AppNav,
    NotificationsManager,
    Toolbar,
  },
})
export default class App extends Vue {
  private isLoggedIn() {
    return !!readUserProfile(this.$store)
  }
}
</script>
