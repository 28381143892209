<template>
  <router-view v-if="loaded"></router-view>
  <v-layout justify-center v-else>
    <v-progress-circular
      indeterminate
      :width="2"
      color="black"
      class="my-5"
    ></v-progress-circular>
  </v-layout>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { dispatchCheckLoggedIn } from "@bugseq-site/app/src/store/modules/api/actions";
import { readUserProfile } from "@bugseq-site/app/src/store/modules/api/getters";

@Component
export default class Start extends Vue {
  private loaded: boolean = false;

  public async mounted() {
    if (this.$route.path === "/app/cognito") {
      this.loaded = true
      return
    }

    if (!readUserProfile(this.$store)) {
      try {
        await dispatchCheckLoggedIn(this.$store);
      } catch (e) {}
    }
    this.loaded = true
  }
}
</script>
