<template>
  <v-container fluid justify-center d-flex>
    <v-flex class="xs12 sm10 md8 lg6 xl4 google-font">
      <v-card class="px-4 pb-4 mt-3">
        <v-card-title primary-title>
          <h3 class="headline py-3">Joining Lab</h3>
        </v-card-title>

        <v-card-text>
          <p>
            In one moment you will be added to the lab...
          </p>

          <p class="mt-3">
            If you are not redirected in a minute, reach out to
            <a href="mailto:support@bugseq.com">support@bugseq.com</a>.
          </p>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import { dispatchAcceptLabInvite } from "@bugseq-site/app/src/store/modules/api/actions";

const ComponentProps = Vue.extend({
  props: {
    invite: String,
  },
});

@Component
export default class LabInviteAccept extends ComponentProps {
  public async mounted() {
    await dispatchAcceptLabInvite(this.$store, { inviteId: this.invite });
  }
}
</script>
