<template>
  <v-app>
    <v-container fluid justify-center d-flex>
      <v-flex class="md12 lg10 xl6">
        <JobResults :job="job" free />
      </v-flex>
    </v-container>
  </v-app>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import JobResults from "@bugseq-site/app/src/components/app/JobResults.vue";

@Component({
  metaInfo() {
    return {
      title: "Results",
    };
  },
  components: { JobResults },
})
export default class FreeResults extends Vue {
  public job!: string;
  public data() {
    return {
      job: this.$route.params.job,
    };
  }
}
</script>
