import { RouteConfig } from 'vue-router'

import Start from '@bugseq-site/app/src/views/main/Start.vue'
import Cognito from '@bugseq-site/app/src/views/Cognito.vue'
import Dashboard from '@bugseq-site/app/src/views/main/Dashboard.vue'
import History from '@bugseq-site/app/src/views/main/History.vue'
import DataExplorer from '@bugseq-site/app/src/views/main/DataExplorer.vue'
import LabsRoot from '@bugseq-site/app/src/views/main/LabsRoot.vue'
import Labs from '@bugseq-site/app/src/views/main/Labs.vue'
import LabNew from '@bugseq-site/app/src/views/main/LabNew.vue'
import Lab from '@bugseq-site/app/src/views/main/Lab.vue'
import LabInviteAccept from '@bugseq-site/app/src/views/main/LabInviteAccept.vue'
import Results from '@bugseq-site/app/src/views/main/Results.vue'
import Dropbox from '@bugseq-site/app/src/views/main/Dropbox.vue'
import UserProfileEdit from '@bugseq-site/app/src/views/main/profile/UserProfileEdit.vue'
import Submitted from '@bugseq-site/app/src/views/main/Submitted.vue'
import UnauthdSubmitted from '@bugseq-site/app/src/views/UnauthdSubmitted.vue'
import Login from '@bugseq-site/app/src/views/Login.vue'
import Register from '@bugseq-site/app/src/views/Register.vue'
import ForgotPassword from '@bugseq-site/app/src/views/ForgotPassword.vue'
import FreeLanding from '@bugseq-site/app/src/views/FreeLanding.vue'
import FreeResults from '@bugseq-site/app/src/views/FreeResults.vue'
import ArchivedPublicationAnalysis from '@bugseq-site/app/src/views/ArchivedPublicationAnalysis.vue'

const routes: RouteConfig[] = [
  {
    path: '/login',
    component: Login
  },
  {
    path: '/register',
    component: Register
  },
  {
    path: '/forgot-password',
    component: ForgotPassword
  },
  {
    path: '/academic',
    component: FreeLanding
  },
  {
    path: '/archived-publication-analysis',
    component: ArchivedPublicationAnalysis
  },
  {
    path: '/academic/results/:job',
    component: FreeResults
  },
  {
    path: '/academic/submitted',
    component: UnauthdSubmitted,
    props: {
      source: 'academic'
    }
  },
  {
    path: '/basespace/submitted',
    component: UnauthdSubmitted,
    props: {
      source: 'basespace'
    }
  },
  {
    path: '/app',
    component: Start,
    redirect: '/app/main/dashboard',
    children: [
      {
        path: 'cognito',
        component: Cognito
      }
    ]
  },
  {
    path: '/app/main',
    component: Start,
    redirect: '/app/main/dashboard',
    children: [
      {
        path: 'dashboard',
        component: Dashboard
      },
      {
        path: 'history',
        component: History
      },
      {
        path: 'explore',
        component: DataExplorer
      },
      {
        path: 'labs',
        component: LabsRoot,
        children: [
          {
            path: '',
            component: Labs
          },
          {
            path: 'new',
            component: LabNew
          },
          {
            path: ':id',
            name: 'labview',
            component: Lab
          }
        ]
      },
      {
        path: 'labs/join/:invite',
        component: LabInviteAccept
      },
      {
        path: 'results/:job',
        name: 'results',
        component: Results
      },
      {
        path: 'dropbox',
        component: Dropbox
      },
      {
        path: 'submitted',
        name: 'submitted',
        component: Submitted
      },
      {
        path: 'profile',
        component: UserProfileEdit
      }
    ]
  },
  {
    path: '*',
    redirect: '/app'
  }
]
export default routes
