<template>
  <v-container fluid justify-center d-flex>
    <v-flex class="xs12 sm10 md8 lg6 xl4 google-font">
      <v-card class="px-4 pb-4 mt-3">
        <v-card-title primary-title>
          <h3 class="headline py-3">Your submission has been received!</h3>
        </v-card-title>

        <v-card-text>
          <p>
            The BugSeq platform has already started analyzing your samples.
            You'll receive an email within a few hours with a link to your
            results.
          </p>

          <p class="mt-3">
            If you don't hear back within 24 hours, or have any questions or
            suggestions, please don't hesitate to reach out to
            <a href="mailto:support@bugseq.com">support@bugseq.com</a>.
          </p>
        </v-card-text>

        <v-card-actions>
          <v-btn
            class="text-capitalize"
            color="primary"
            :href="getSubmissionLink()"
            >Submit Another Analysis</v-btn
          >
          <v-btn class="text-capitalize" flat color="primary" href="/"
            >Return Home</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

const ComponentProps = Vue.extend({
  props: {
    source: {
      type: String,
      required: false,
    },
  },
});

export default class Submitted extends ComponentProps {
  public getSubmissionLink() {
    switch (this.source) {
      case "academic":
        return "/academic";
      case "basespace":
        return "https://basespace.illumina.com";
      default:
        return "/app/main/dashboard";
    }
  }
}
</script>
