<template>
  <v-card class="elevation-12 mt-4">
    <v-toolbar dark color="primary">
      <v-toolbar-title>Login</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-form lazy-validation class="px-5 py-2">
      <v-card-text>
        <div v-if="mfaVariant === null">
          <div class="my-3">
            Please select a method to authenticate
          </div>
          <v-btn
            block
            v-for="mfaOpt in mfaOpts"
            :key="mfaOpt"
            @click="() => selectVariant(mfaOpt, true)"
            class="text-capitalize"
            color="primary"
          >
            {{ getVariantUserFriendlyName(mfaOpt) }}
          </v-btn>
        </div>
        <div v-else>
          <div class="my-3" v-if="mfaVariant === MFAVariant.TOTP">
            Please enter the code from your Authenticator app
          </div>
          <div class="my-3" v-else-if="mfaVariant === MFAVariant.SMS">
            Please enter the code that was just sent via text message
          </div>
          <v-text-field
            class="required"
            label="Code"
            name="Code"
            v-model="code"
            v-validate="'required'"
            :error-messages="getError(fields, errors, 'Code')"
          ></v-text-field>
          <div v-if="errorMessage" class="red--text">{{ errorMessage }}</div>
          <div class="mb-4">
            Need help? <a href="mailto:support@bugseq.com?subject=Trouble logging in to BugSeq&body=Hi! I'm having trouble logging into BugSeq.">contact support</a>.
          </div>
          <v-btn
            block
            type="submit"
            @click="submit"
            class="text-capitalize"
            color="primary"
            :disabled="errors.items.length > 0 || inProgress"
          >
            Log In
          </v-btn>
          <v-btn
            block
            v-if="!!errorMessage"
            @click="returnToLogin"
            class="text-capitalize"
          >
            Return to Log In
          </v-btn>
        </div>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script lang="ts">
import { PropType } from "vue";
import { Component, Vue } from "vue-property-decorator";
import { confirmSignIn } from 'aws-amplify/auth';
import { LoginStep } from "@bugseq-site/app/src/components/app/LoginTypes";

enum MFAVariant {
  TOTP,
  SMS,
}

const ComponentProps = Vue.extend({
  props: {
    goToStep: Function as PropType<(step: LoginStep) => void>,
  },
});

@Component({
  methods: {
    getError: (fields, errors, field) => {
      if (!(field in fields)) {
        return "";
      }
      if (!fields[field].dirty) {
        return "";
      }
      return errors.collect(field);
    },
  },
  data() {
    return {
      MFAVariant,
    };
  },
})
export default class LoginMFA extends ComponentProps {
  private code: string = "";
  private errorMessage: string = "";

  private mfaVariant: MFAVariant | null = null;
  private mfaOpts: MFAVariant[] = [];

  private inProgress = false;

  public mounted() {
    const mfaOpts = this.$route.query.mfaopts as string[]
    for (const variant of mfaOpts) {
      switch (variant) {
        case 'TOTP':
          this.mfaOpts.push(MFAVariant.TOTP)
          break
        case 'SMS':
          this.mfaOpts.push(MFAVariant.SMS)
          break
        default:
          console.error(`unrecognized MFA variant: ${variant}`)
      }
    }

    if (this.mfaOpts.length === 1) {
      this.selectVariant(this.mfaOpts[0], false)
    }
  }

  public async selectVariant(variant: MFAVariant, sendSelection: boolean) {
    this.mfaVariant = variant

    // if multiple mfa modes are enabled, we need to tell aws which one we're using
    if (sendSelection) {
      switch (variant) {
        case MFAVariant.TOTP:
          await confirmSignIn({
            challengeResponse: "TOTP",
          });
          break
        case MFAVariant.SMS:
          // this sends the code via SMS
          await confirmSignIn({
            challengeResponse: "SMS",
          });
          break
      }
    }
  }

  public getVariantUserFriendlyName(variant: MFAVariant) {
    switch (variant) {
      case MFAVariant.TOTP:
        return "Authenticator App"
      case MFAVariant.SMS:
        return "Text Message (SMS)"
    }
  }

  public returnToLogin() {
    this.goToStep(LoginStep.Main)
  }

  public async submit(event) {
    event.preventDefault()

    if (await this.$validator.validateAll()) {
      this.inProgress = true;

      try {
        const { nextStep } = await confirmSignIn({
          challengeResponse: this.code,
        });
        if (nextStep.signInStep !== "DONE") {
          console.error("unrecognized next step", nextStep)
          throw new Error("Unknown error")
        }

        this.goToStep(LoginStep.Done)
      } catch (error) {
        if (error instanceof Error) {
          this.errorMessage = error.message
        } else {
          console.error("could not parse error", error)
          this.errorMessage = "Unknown error"
        }
      } finally {
        this.inProgress = false;
      }
    }
  }
}
</script>

<style scoped>
.required >>> label::after {
  content: " *";
  color: red;
}
</style>
