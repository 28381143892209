<template>
  <LabsOverview />
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import LabsOverview from "@bugseq-site/app/src/components/app/LabsOverview.vue";

@Component({
  components: {
    LabsOverview,
  },
  metaInfo: {
    title: "Labs",
  },
})
export default class LabsPage extends Vue {}
</script>
