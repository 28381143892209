<template>
  <div class="pa-4">
    <div class="pb-3 headline">
      Labs
    </div>
    <div class="subheading">
      Labs allow you to collaborate with other team members. You can
      share analyses with members of your lab so that they can also access
      results in the BugSeq portal. Phylogenetic analyses will also
      incorporate previously analyzed samples from your lab.
    </div>
    <v-layout column class="my-4">
      <v-flex
        xs12
      >
        <v-btn
          class="text-capitalize pa-4"
          color="primary"
          block
          to="/app/main/labs/new"
        >
          Create Lab <v-icon class="ml-2">group_add</v-icon>
        </v-btn>
      </v-flex>
      <v-flex
        xs12
        v-for="lab in labs"
        :key="lab.id"
      >
        <v-btn
          class="text-none pa-4"
          color="primary"
          block
          outline
          :to="{ name: 'labview', params: { id: lab.id } }"
        >
          {{ lab.name }}
        </v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import { readLabs, readUserProfile } from "@bugseq-site/app/src/store/modules/api/getters";

@Component
export default class LabsOverview extends Vue {
  get user() {
    return readUserProfile(this.$store);
  }

  get labs() {
    return readLabs(this.$store).sort((a, b) => a.name.localeCompare(b.name));
  }
}
</script>
